<template>
  <div>
    <BaseButton
      @click="openModal()"
    >
      <template slot="iconLeft">
        <Icon
          view-box="0 0 24 24"
          class="w-5 h-5"
        >
          <PaperAirplane />
        </Icon>
      </template>
      Invite Candidates
    </BaseButton>

    <Modal
      :open="modalOpen"
      size="max-w-2xl"
      @close="closeModal()"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Shareable link
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="closeModal()"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <div class="p-6 mx-auto bg-gray-100">
        <div v-if="(job.exams && job.exams.length > 0) || (job.customExams && job.customExams.length > 0)">
          <p>Share this link to invite candidates to take your assessments for this job.</p>

          <p class="mt-4 mb-2">
            Included tests:
          </p>
          <ul class="flex flex-wrap space-x-2">
            <li
              v-for="exam in job.exams"
              :key="exam.slug"
              class="inline-flex rounded items-center py-1.5 px-2 text-sm font-medium bg-secondary text-white mb-2"
            >
              <span class="inline-flex items-center space-x-1">
                <ExamIcon
                  :icon="exam.icon"
                  class="w-4 h-4"
                />
                <span>{{ exam.name }}</span>
              </span>
            </li>
            <li
              v-for="customExam in job.customExams"
              :key="customExam.slug"
              class="inline-flex rounded items-center py-1.5 px-2 text-sm font-medium bg-secondary text-white mb-2"
            >
              <span class="inline-flex items-center space-x-1">
                <ExamIcon
                  icon="puzzle"
                  class="w-4 h-4"
                />
                <span>{{ customExam.name }}</span>
              </span>
            </li>
          </ul>
          <div class="flex items-center justify-between mt-6 mb-1">
            <p
              class="text-sm text-gray-800"
            >
              Send this link to your candidates
            </p>
            <transition
              enter-active-class="transition-all duration-150 ease-out"
              leave-active-class="transition-all duration-150 ease-in"
              enter-class="opacity-0"
              leave-class="opacity-100"
              enter-to-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <span
                v-if="copyUrlMessage"
                class="text-xs text-gray-700"
              >{{ copyUrlMessage }}</span>
            </transition>
          </div>
          <div class="relative flex items-center">
            <input
              v-model="candidateSittingUrl"
              type="text"
              class="w-full form-input"
            >
            <button
              class="absolute inset-y-0 right-0 flex inline-flex items-center h-full p-0 px-4 duration-150 rounded-tr rounded-br shadow-none focus:outline-none bg-secondary hover:bg-secondary-400"
              @click="copyOpenSignUpUrl()"
            >
              <svg
                viewBox="0 0 24 24"
                class="w-6 h-6"
              ><rect
                width="14"
                height="14"
                x="3"
                y="3"
                fill="none"
                stroke="#FFF"
                stroke-width="1"
              ></rect><rect
                width="14"
                height="14"
                x="7"
                y="7"
                fill="none"
                stroke="#FFF"
                stroke-width="1"
              ></rect></svg>
              <span class="text-sm text-white">Copy</span>
            </button>
          </div>

          <p class="mt-6 text-sm">
            Or you can invite candidates by
            <router-link
              :to="{ name: 'client-assessments-invite', params: { id: job.uuid } }"
              class="underline"
            >
              uploading a spreadsheet
            </router-link>
          </p>
        </div>
        <div v-else>
          <p>
            Before you can share a link with candidates, you’ll need to include at least one test.
          </p>
          <BaseButton
            variant="salmon"
            class="mt-4"
            :to="{ name: 'client-assessments-exams', params: { id: job.uuid } }"
          >
            Choose Tests
          </BaseButton>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from '@components/Modal'
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'
import ExamIcon from '@components/Icons/Exams/ExamIcon'
import PaperAirplane from '@components/Icons/PaperAirplane'

import { mapGetters } from 'vuex'

export default {
  components: {
    Modal,
    Icon,
    Plus,
    ExamIcon,
    PaperAirplane
  },

  props: {
    job: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      modalOpen: false,
      copyUrlMessage: null
    }
  },

  computed: {
    ...mapGetters({
      exams: 'organisations/exams',
      examBySlug: 'organisations/examBySlug'
    }),

    /**
     * @return string
     */
    candidateSittingUrl() {
      return process.env.VUE_APP_SPLASH_URL + '/assessments/job/' + this.job.uuid
    },

    examNames() {
      if (!this.job.examSlugs) {
        return null
      }

      if (!this.exams) {
        return null
      }

      return this.job.examSlugs
        .map(examSlug => {
          return this.examBySlug(examSlug)
        })
        .map(exam => {
          return exam.name
        })
    }
  },

  methods: {
    /**
     * Copy text to the user’s clipboard
     */
    copyOpenSignUpUrl() {
      this.$copyText(this.candidateSittingUrl)
        .then(e => {
          this.copyUrlMessage = 'Copied'
        })
        .catch(error => {
          this.copyUrlMessage = 'Sorry, couldn’t copy to your clipboard'
          throw error
        })
    },

    /**
     * Opens modal
     */
    openModal() {
      this.modalOpen = true
    },

    /**
     * Cloess modal
     */
    closeModal() {
      this.modalOpen = false
    }
  }
}
</script>
