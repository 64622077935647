<template>
  <div class="grid grid-cols-1 gap-4 lg:col-span-2">
    <section>
      <WorkHistory :work-history="profile ? profile.workHistory : []" />
    </section>

    <section class="grid md:grid-cols-2 gap-4">
      <RoleExperience :role-experience="profile ? profile.roleExperience : []" />
      <Skills :skills="profile ? profile.skills : []" />
    </section>

    <section>
      <EducationHistory :education-history="profile ? profile.educationHistory : []" />
    </section>

    <div class="grid md:grid-cols-2 gap-4">
      <EmploymentPreferences
        :employment="profile ? profile.employment : null"
        :employment-status="profile ? profile.employmentStatus : null"
      />

      <CompanyPreference :company-preference="profile ? profile.companyPreference : null" />
    </div>
  </div>
</template>

<script>
import WorkHistory from '@components/CandidateWizardProfile/WorkHistory'
import RoleExperience from '@components/CandidateWizardProfile/RoleExperience'
import Skills from '@components/CandidateWizardProfile/Skills'
import EducationHistory from '@components/CandidateWizardProfile/EducationHistory'
import EmploymentPreferences from '@components/CandidateWizardProfile/EmploymentPreferences'
import CompanyPreference from '@components/CandidateWizardProfile/CompanyPreference'

export default {
  components: {
    WorkHistory,
    RoleExperience,
    Skills,
    EducationHistory,
    EmploymentPreferences,
    CompanyPreference
  },

  props: {
    profile: {
      type: Object,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
