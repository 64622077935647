<template>
  <div class="lg:flex flex-col relative z-10 w-full flex-grow flex-shrink-0 bg-white rounded-lg shadow">
    <div class="flex-shrink-0 pb-6 shadow">
      <Tabs
        :tabs="tabs"
        :active-tab="activeTab"
        @switchToTab="activeTab = $event"
      >
        <template v-slot:suffix="{ tab }">
          <span
            class="bg-gray-100 text-gray-900 hidden ml-2 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block"
          >{{ counts(tab) }}</span>
        </template>
      </Tabs>

      <div class="px-6 space-y-4 mt-6">
        <div class="flex items-center justify-between">
          <SearchCandidates
            class="mb-6"
            @search="handleSearch($event)"
          />
          <CandidatesFilterButton
            v-model="filters"
            :loading="loading"
            class="md:ml-2"
          />
        </div>
        <div
          v-if="candidates.length"
          class="flex justify-between items-center h-6"
        >
          <div class="flex">
            <span class="flex">
              <label class="flex items-center">
                <input
                  v-model="allSelected"
                  type="checkbox"
                  class="form-checkbox rounded"
                  @change="selectAll"
                />

                <span class="ml-2 text-sm text-gray-600">
                  Select all <span class="text-xs">({{ candidates.length }})</span>
                </span>
              </label>
            </span>
            <transition
              enter-active-class="transition-all duration-500 ease-out"
              leave-active-class="transition-all duration-150 ease-in"
              enter-class="opacity-0"
              leave-class="opacity-100"
              enter-to-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <CandidatesBulkActionsButton
                v-if="bulkActionsActive"
                :loading="loading"
                :filter="activeTab"
                class="ml-4"
                @move-to-stage="$emit('move-to-stage', selectedCandidates)"
                @qualification-status-update="$emit('qualification-status-update', { action: $event, candidates: selectedCandidates})"
                @delete-candidates="$emit('delete-candidates', selectedCandidates)"
              />
            </transition>
          </div>

          <SortCandidatesButton
            :loading="loading"
            :candidates="candidates"
            @sort="$emit('sort', $event)"
          />
        </div>
      </div>
    </div>

    <InfiniteScroll
      v-if="candidates"
      :loading="loading"
      :items="candidates"
      :last-page="lastPage"
      @fetchPage="$emit('fetchCandidates', $event)"
    >
      <template v-slot:item="{ item }">
        <a
          href="javascript:;"
          :class="['block p-6 hover:bg-gray-50', viewingCandidate && viewingCandidate.id === item.id ? 'bg-gray-50 hover:bg-gray-50 shadow-inner' : 'bg-white']"
          @click="$emit('fetchCandidate', item.id)"
        >
          <div class="flex text-left">
            <span class="flex mr-4">
              <label class="flex items-center">
                <input
                  v-model="selectedCandidates"
                  type="checkbox"
                  class="form-checkbox rounded"
                  :value="{ name: `${item.firstName} ${item.surname}`, id: item.id }"
                  @click.stop="select"
                />

                <span class="sr-only">
                  Select candidate
                </span>
              </label>
            </span>
            <div class="mr-4 flex-shrink-0">
              <Photo
                :photo="item.profilePhoto"
                size="w-10 h-10"
              />
            </div>
            <div>
              <CandidateName class="font-medium text-lg truncate w-60" >
                {{ item.firstName }} {{ item.surname }}
              </CandidateName>
              <p
                v-if="item.finishedAt"
                class="text-xs text-gray-600"
              >Tests completed: {{ formatDate(item.finishedAt) }}</p>
              <p
                v-else
                class="text-xs text-gray-600"
              >Testing in progress</p>
            </div>
          </div>
        </a>
      </template>
    </InfiniteScroll>
  </div>
</template>

<script>
import Tabs from '@components/CandidateWizardProfile/Tabs'
import Photo from '@components/CandidateWizardProfile/Photo'
import CandidatesBulkActionsButton from '@components/Candidates/CandidatesBulkActionsButton'
import SortCandidatesButton from '@components/Candidates/SortCandidatesButton'
import InfiniteScroll from '@components/InfiniteScroll'
import SearchCandidates from '@components/Candidates/SearchCandidates'
import CandidatesFilterButton from '@components/Candidates/CandidatesFilterButton'
import CandidateName from '@components/Candidates/CandidateName'

import { formatDate } from '@utils/formatDate'

export default {
  components: {
    Tabs,
    Photo,
    CandidatesBulkActionsButton,
    SortCandidatesButton,
    InfiniteScroll,
    SearchCandidates,
    CandidatesFilterButton,
    CandidateName
  },

  props: {
    candidates: {
      type: Array,
      default: null
    },

    candidateCount: {
      type: Object,
      default: null
    },

    viewingCandidate: {
      type: Object,
      default: null
    },

    lastPage: {
      type: Number,
      default: 1
    },

    stage: {
      type: Object,
      default: null
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      formatDate,
      activeTab: 'qualified',
      tabs: [
        {
          id: 'qualified',
          name: 'Qualified'
        },
        {
          id: 'disqualified',
          name: 'Disqualified'
        }
      ],
      search: '',
      filters: [],

      allSelected: false,
      selectedCandidates: []
    }
  },

  computed: {
    bulkActionsActive() {
      return this.selectedCandidates.length || this.allSelected
    }
  },

  watch: {
    activeTab(val) {
      if (val === 'qualified') {
        this.$emit('candidatesQualifiedFilter', 'qualified_only')
      } else if (val === 'disqualified') {
        this.$emit('candidatesQualifiedFilter', 'disqualified_only')
      }
    },

    filters: {
      handler(filters) {
        if (filters) {
          this.$emit('input', filters)
        }
      },
      deep: true
    }
  },

  methods: {
    selectAll() {
      this.selectedCandidates = []

      if (this.allSelected) {
        for (const candidate in this.candidates) {
          this.selectedCandidates.push({
            name: `${this.candidates[candidate].firstName} ${this.candidates[candidate].surname}`,
            id: this.candidates[candidate].id
          })
        }
      }
    },

    select() {
      this.allSelected = false
    },

    /**
     * @param {string} tabId
     */
    counts(tabId) {
      if (!this.candidateCount || !this.stage) {
        return '-'
      }
      const counts = {
        qualified: this.candidateCount[this.stage.slug].qualified,
        disqualified: this.candidateCount[this.stage.slug].disqualified
      }
      return counts[tabId]
    },

    handleSearch(event) {
      this.search = event
      this.$emit('search', event)
    }
  }
}
</script>
