<template>
  <div class="sm:flex items-center sm:space-x-5">
    <Photo :photo="profile ? profile.photo : null" />

    <div class="mt-4 text-center sm:mt-0 sm:text-left">
      <p class="text-2xl font-bold text-gray-800 leading-8">
        <CandidateName>
          <slot name="name" />
        </CandidateName>
      </p>
      <p class="text-gray-600 text-xs truncate w-72 text-center mx-auto sm:text-left sm:mx-0">
        <template v-if="maskedAttributes.includes('email')">
          Email hidden
        </template>
        <template v-if="!maskedAttributes.includes('email')">
          <slot
            name="email"
          />
        </template>
      </p>

      <ul
        v-if="profile"
        class="space-x-12 mt-3 text-sm flex items-center justify-center md:justify-start"
      >
        <li v-if="profile.location && profile.location.formattedAddress">
          <span class="font-medium text-gray-600">Location:</span> <span class="text-gray-800">{{ profile.location.formattedAddress }}</span>
        </li>
        <li v-if="profile.employmentStatus.noticePeriod">
          <span class="font-medium text-gray-600">Notice Period:</span> <span class="text-gray-800">{{ profile.employmentStatus.noticePeriod }}</span>
        </li>
      </ul>

      <AntiCheatResults
        v-if="hasAntiCheatData"
        class="mt-4"
        :candidate="candidate"
      />
    </div>
  </div>
</template>

<script>
import Photo from '@components/CandidateWizardProfile/Photo'
import CandidateName from '@components/Candidates/CandidateName'
import AntiCheatResults from '@components/Candidates/AntiCheatResults'

import { mapGetters } from 'vuex'

export default {
  components: {
    Photo,
    CandidateName,
    AntiCheatResults
  },

  props: {
    profile: {
      type: Object,
      default: null
    },
    candidate: {
      type: Object,
      default: null
    }
  },

  computed: {
    ...mapGetters({
      maskedAttributes: 'organisations/maskedAttributes'
    }),

    hasAntiCheatData() {
      if (!this.candidate) {
        return false
      }
      // @TODO - We dont display anti-cheat data for legacy
      // candidates. Be good to find a better way to deal with legacy
      // candidates who dont have any anti-cheat data.
      return Boolean(this.candidate.attempts.find(attempt => attempt.ipAddressChanged !== null))
    }
  }
}
</script>
