<template>
  <div class="bg-white w-full">
    <div class="p-10 sm:flex-shrink-0 max-w-sm text-center mx-auto">
      <Results />
      <h2 class="text-3xl mt-6">
        {{ title }}
      </h2>
      <p class="text-gray-600 mb-6">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
import Results from '@components/Vectors/Results'

export default {
  components: {
    Results
  },

  props: {
    title: {
      type: String,
      default: 'No results yet'
    },

    description: {
      type: String,
      default: 'Results will appear when a candidate completes a test'
    }
  }
}
</script>
