<template>
  <ul class="flex items-center space-x-4 h-9">
    <li>
      <TooltipButton>
        <template v-slot:content>
          <DeleteCandidateButton
            :candidates="candidates"
            @candidates-deleted="$emit('update')"
          />
        </template>
        <template v-slot:tooltip>
          Delete Candidate
        </template>
      </TooltipButton>
    </li>

    <li v-if="filter === 'qualified_only'">
      <TooltipButton>
        <template v-slot:content>
          <DisqualifyCandidateButton
            :candidates="candidates"
            :job="job"
            @candidates-disqualified="$emit('update')"
          />
        </template>
        <template v-slot:tooltip>
          Disqualify Candidate
        </template>
      </TooltipButton>
    </li>

    <li v-if="filter === 'disqualified_only'">
      <TooltipButton>
        <template v-slot:content>
          <RequalifyCandidateButton
            :candidates="candidates"
            :job="job"
            @candidates-requalified="$emit('update')"
          />
        </template>
        <template v-slot:tooltip>
          Requalify Candidate
        </template>
      </TooltipButton>
    </li>

    <li>
      <TooltipButton>
        <template v-slot:content>
          <EditCandidateButton
            class="text-sm"
            :candidate="candidate"
            @candidateUpdated="$emit('candidate-edited')"
          />
        </template>
        <template v-slot:tooltip>
          Edit Candidate
        </template>
      </TooltipButton>
    </li>

    <li
      class="flex flex-shrink-0"
    >
      <MoveToStageButton
        :candidate="candidate"
        :pipeline="pipeline"
        :stage="stage"
        :filter="filter"
        @move-to-stage="$emit('move-to-stage', $event)"
      />
      <MoveToStageDropdown
        :candidate="candidate"
        :pipeline="pipeline"
        :stage="stage"
        :filter="filter"
        @move-to-stage="$emit('move-to-stage', $event)"
      />
    </li>
  </ul>
</template>

<script>
import DeleteCandidateButton from '@components/Candidates/DeleteCandidateButton'
import DisqualifyCandidateButton from '@components/CandidateWizardProfile/DisqualifyCandidateButton'
import RequalifyCandidateButton from '@components/CandidateWizardProfile/RequalifyCandidateButton'
import MoveToStageButton from '@components/CandidateWizardProfile/MoveToStageButton'
import MoveToStageDropdown from '@components/CandidateWizardProfile/MoveToStageDropdown'
import TooltipButton from '@components/TooltipButton'
import EditCandidateButton from '@components/Candidates/EditCandidateButton'

export default {
  components: {
    DeleteCandidateButton,
    DisqualifyCandidateButton,
    RequalifyCandidateButton,
    MoveToStageButton,
    MoveToStageDropdown,
    TooltipButton,
    EditCandidateButton
  },

  props: {
    candidate: {
      type: Object,
      default: null
    },

    stage: {
      type: Object,
      default: null
    },

    pipeline: {
      type: Array,
      default: null
    },

    job: {
      type: Object,
      default: null
    },

    filter: {
      type: String,
      default: 'qualified_only'
    }
  },

  computed: {
    candidates() {
      const candidates = []
      candidates.push(this.candidate)
      return candidates
    }
  }
}
</script>
