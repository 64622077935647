<template>
  <div class="bg-white w-full py-15">
    <div class="px-20">
      <h2 class="text-3xl">
        Invite candidates to take your assessment
      </h2>
      <p class="text-gray-600 pb-10 text-xl">
        Share with your candidates
      </p>
    </div>
    <div class="grid grid-cols-2">
      <div class="px-20">
        <div class="max-w-xs">
          <Percentages />
        </div>
      </div>
      <div class="px-20">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import Percentages from '@components/Vectors/Percentages'

export default {
  components: {
    Percentages
  },

  data() {
    return {
      satelliteName: process.env.VUE_APP_SATELLITE_NAME
    }
  }
}
</script>
