<template>
  <div class="rounded-lg bg-white shadow p-6">
    <h2 class="text-lg font-medium mb-8">
      Company Preferences
    </h2>
    <div class="space-y-8 -mt-8">
      <dl class="grid grid-cols-1 gap-y-8 sm:grid-cols-2 pt-8 sm:divide-x divide-gray-300 divide-dashed">
        <div class="sm:col-span-1 sm:pr-4">
          <dt
            v-if="companyPreference && companyPreference.size.length"
            class="text-sm font-medium text-gray-500"
          >
            Size
          </dt>
          <dd
            v-if="companyPreference && companyPreference.size.length"
            class="mt-1 text-sm text-gray-800"
          >
            <ul class="space-y-2" >
              <li
                v-for="(size, index) in companyPreferenceSizesFormatted"
                :key="index"
              >
                {{ size }}
              </li>
            </ul>
          </dd>
          <dd
            v-else
            class="border-2 border-dashed border-gray-200 rounded-lg h-32 text-gray-400 text-sm flex flex-col justify-center items-center"
          >
            <p>Incomplete</p>
          </dd>
        </div>
        <div class="sm:col-span-1 sm:pl-4">
          <dt
            v-if="companyPreference && companyPreference.culture.length"
            class="text-sm font-medium text-gray-500"
          >
            Culture
          </dt>
          <dd
            v-if="companyPreference && companyPreference.culture.length"
            class="mt-1 text-sm text-gray-800"
          >
            <span>{{ companyPreference.culture.join(', ') }}</span>
          </dd>
          <dd
            v-else
            class="border-2 border-dashed border-gray-200 rounded-lg h-32 text-gray-400 text-sm flex flex-col justify-center items-center"
          >
            <p>Incomplete</p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    companyPreference: {
      type: Object,
      default: null
    }
  },

  computed: {
    /**
     * @return {Array}
     */
    companyPreferenceSizesFormatted() {
      if (!this.companyPreference) {
        return
      }

      const definitions = {
        Small: '0 - 25',
        Medium: '25 - 100',
        Large: '100+'
      }

      return this.companyPreference.size.map(preference => {
        return definitions[preference]
      })
    }
  }
}
</script>
