<template>
  <AccessibleDropdown
    :value="value"
    :options="jobs"
    :search-active="true"
    :pre-select="true"
    :filter-function="applyJobSearchFilter"
    @input="$emit('input', $event)"
    @searching="jobSearchQuery = $event"
  >
    <template v-slot:button="{ isOpen }">
      <div :class="['flex items-baseline text-2xl font-bold leading-8', isOpen ? 'text-gray-600' : '']">
        <transition
          mode="out-in"
          enter-active-class="transition-all duration-300 ease-out"
          leave-active-class="transition-all duration-150 ease-in"
          enter-class="opacity-0"
          leave-class="opacity-100"
          enter-to-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <div
            v-if="value !== null"
            class="flex-1 min-w-0"
          >
            <span class="truncate inline-flex text-left">{{ value.name }}</span>
            <Icon
              view-box="0 0 284.929 284.929"
              class="ml-2 w-3 h-3 transform -rotate-90"
            >
              <Chevron />
            </Icon>
          </div>
          <Loader v-else />
        </transition>
      </div>
    </template>

    <template v-slot:buttonContent="{ option }">
      <span class="flex justify-between items-center w-full min-w-0">
        <span class="truncate">{{ option.name }}</span>

        <span class="truncate">
          <svg
            v-if="option.uuid === value.uuid"
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-secondary"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </span>
      </span>
    </template>
  </AccessibleDropdown>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Chevron from '@components/Icons/Chevron'
import AccessibleDropdown from '@components/AccessibleDropdown'
import Loader from '@components/Loader'

export default {
  components: {
    Icon,
    Chevron,
    AccessibleDropdown,
    Loader
  },

  props: {
    value: {
      type: Object,
      default: null
    },

    jobs: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      jobSearchQuery: ''
    }
  },

  methods: {
    applyJobSearchFilter() {
      return this.jobs.filter(job => job.name.toLowerCase().includes(this.jobSearchQuery.toLowerCase()))
    }
  }
}
</script>
