<template>
  <BaseButton
    size="small"
    class="rounded-tr-none rounded-br-none"
    :disabled="filter === 'disqualified_only'"
    @click="$emit('move-to-stage', { stageSlug: nextStage.slug, candidateId: candidate.id })"
  >
    Move to {{ nextStage.name }}
  </BaseButton>
</template>

<script>
export default {
  props: {
    candidate: {
      type: Object,
      default: null
    },

    pipeline: {
      type: Array,
      default: null
    },

    stage: {
      type: Object,
      default: null
    },

    filter: {
      type: String,
      default: ''
    }
  },

  computed: {
    nextStage() {
      if (!this.stage && !this.pipeline.length) {
        return {
          name: 'next stage'
        }
      }

      const stage = this.pipeline.find(stage => stage.slug === this.stage.slug)
      const currentIndex = this.pipeline.indexOf(stage)
      const nextIndex = (currentIndex + 1) % this.pipeline.length
      return this.pipeline[nextIndex]
    }
  }
}
</script>
