<template>
  <AccessibleDropdown
    :options="options"
    :loading="loading"
    @input="handleSort($event)"
  >
    <template v-slot:button="{ isOpen }">
      <span
        class="flex items-center text-sm rounded-lg px-3 py-1"
        :class="[isOpen ? 'bg-gray-300' : 'bg-gray-100']"
      >
        <span>Sort</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-3 w-3 flex-shrink-0 ml-2"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </span>
    </template>

    <template v-slot:buttonContent="{ option }">
      <span class="flex justify-between items-center w-full">
        <span class="flex items-center">
          <component
            :is="option.icon"
            v-if="option.icon"
            class="h-6 w-6 mr-2 text-gray-600"
          />
          <span>{{ option.name }}</span>
        </span>
        <span>
          <svg
            v-if="option.order === chosenItem && !loading"
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-secondary"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </span>
      </span>
    </template>
  </AccessibleDropdown>
</template>

<script>
import AccessibleDropdown from '@components/AccessibleDropdown'

export default {
  components: {
    AccessibleDropdown
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    candidates: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      chosenItem: null
    }
  },

  computed: {
    options() {
      return [
        {
          name: 'A to Z',
          order: 'asc',
          field: 'name',
          icon: () => import('@components/Icons/Isolated/AtoZ')
        },
        {
          name: 'Z to A',
          order: 'desc',
          field: 'name',
          icon: () => import('@components/Icons/Isolated/ZtoA')
        }
      ]
    }
  },

  methods: {
    handleSort(event) {
      this.$emit('sort', { page: 1, sort_by: event.field, sort_order: event.order, per_page: this.candidates.length })
      this.chosenItem = event.order
    }
  }
}
</script>
