<template>
  <div>
    <div class="md:hidden">
      <label
        for="selected-tab"
        class="sr-only"
      >
        Select a tab
      </label>
      <select
        id="selected-tab"
        v-model="selected"
        name="selected-tab"
        class="w-full form-select rounded"
        @change="switchToTab(selected)"
      >
        <option
          v-for="tab in tabs"
          :key="tab.id"
        >
          {{ tab.name }}
        </option>
      </select>
    </div>
    <div class="hidden md:block">
      <div class="border-b border-gray-300 px-6">
        <nav class="-mb-px flex space-x-8">
          <a
            v-for="tab in tabs"
            :key="tab.id"
            href="javascript:;"
            :class="['whitespace-no-wrap py-4 px-1 border-b-2 border-transparent text-sm text-gray-600 hover:border-gray-600 hover:text-gray-600 duration-150 ease-out', { 'border-secondary text-secondary hover:text-secondary hover:border-secondary' : activeTab === tab.id }]"
            @click="switchToTab(tab.id)"
          >
            <span>{{ tab.name }}</span>
            <slot
              name="suffix"
              :tab="tab.id"
            />
          </a>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      default: null
    },
    activeTab: {
      type: String,
      default: 'aptitude'
    },
    count: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      selected: this.activeTab
    }
  },

  watch: {
    /**
     * @param {string} tab
     */
    activeTab(tab) {
      if (tab) {
        this.$emit('switchToTab', tab)
      }
    }
  },

  methods: {
    /**
     * @param {string} tab
     */
    switchToTab(tab) {
      this.$emit('switchToTab', tab)
    }
  }
}
</script>
