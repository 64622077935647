<template>
  <div class="rounded-lg bg-white shadow p-6">
    <h2 class="text-lg font-medium mb-8">
      Work History
    </h2>
    <div
      v-if="workHistory.length"
      class="space-y-8 divide-y divide-gray-300 divide-dashed -mt-8"
    >
      <dl
        v-for="(entry, index) in workHistory"
        :key="index"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3 pt-8"
      >
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Title
          </dt>
          <dd class="mt-1 text-sm text-gray-800">
            {{ entry.title }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Employer
          </dt>
          <dd class="mt-1 text-sm text-gray-800">
            {{ entry.employer }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Tenure
          </dt>
          <dd class="mt-1 text-sm text-gray-800">
            <CandidateTenure
              :tenure="{
                start: entry.start,
                end: entry.end,
                current: entry.current
              }"
            />
          </dd>
        </div>

        <div
          v-if="entry.highlights"
          class="sm:col-span-3"
        >
          <dt class="text-sm font-medium text-gray-500">
            Highlights &amp; Achievements
          </dt>
          <dd class="mt-1 max-w-prose text-sm text-gray-800 space-y-5">
            <p>
              {{ entry.highlights }}
            </p>
          </dd>
        </div>
      </dl>
    </div>
    <dd
      v-else
      class="border-2 border-dashed border-gray-200 rounded-lg h-32 text-gray-400 text-sm flex flex-col justify-center items-center"
    >
      <p>Incomplete</p>
    </dd>
  </div>
</template>

<script>
import CandidateTenure from '@components/Candidates/CandidateTenure'

export default {
  components: {
    CandidateTenure
  },

  props: {
    workHistory: {
      type: Array,
      default: null
    }
  }
}
</script>
