<template>
  <div>
    <ul class="flex justify-center items-center py-6">
      <li class="px-2">
        <button
          v-if="canGoToStart"
          type="button"
          class="focus:outline-none text-gray-600"
          aria-label="Go to first page"
          @click="goTo(1)"
        >
          <Icon
            width="12px"
            height="12px"
            view-box="0 0 284.929 284.929"
          >
            <Chevron double />
          </Icon>
        </button>
        <span
          v-else-if="!canGoToStart"
          class="text-gray-400"
          aria-label="Go to first page"
        >
          <Icon
            width="12px"
            height="12px"
            view-box="0 0 284.929 284.929"
          >
            <Chevron double />
          </Icon>
        </span>
      </li>

      <li class="px-2 pr-6">
        <button
          v-if="canGoBack"
          type="button"
          class="focus:outline-none text-gray-600"
          aria-label="Go to previous page"
          @click="goTo(currentPage - 1)"
        >
          <Icon
            width="12px"
            height="12px"
            view-box="0 0 284.929 284.929"
          >
            <Chevron />
          </Icon>
        </button>
        <span
          v-else-if="!canGoBack"
          class="text-gray-400"
          aria-label="Go to previous page"
        >
          <Icon
            width="12px"
            height="12px"
            view-box="0 0 284.929 284.929"
          >
            <Chevron />
          </Icon>
        </span>
      </li>

      <li
        v-for="(page, index) in pages"
        :key="index"
        class="px-2 text-sm"
      >
        <button
          v-if="!page.isCurrentPage"
          type="button"
          class="focus:outline-none bg-gray-300 py-1 px-2 leading-none"
          :aria-label="`Go to page number ${page.name}`"
          @click="goTo(page.name)"
        >
          {{ page.name }}
        </button>
        <span
          v-else-if="page.isCurrentPage"
          class="py-1 px-2 leading-none bg-secondary text-white"
        >
          {{ page.name }}
        </span>
      </li>

      <li class="px-2 pl-6">
        <button
          v-if="canGoForward"
          type="button"
          class="focus:outline-none text-gray-600"
          aria-label="Go to next page"
          @click="goTo(currentPage + 1)"
        >
          <Icon
            width="12px"
            height="12px"
            view-box="0 0 284.929 284.929"
            class="transform rotate-180"
          >
            <Chevron />
          </Icon>
        </button>
        <span
          v-else-if="!canGoForward"
          class="text-gray-400"
        >
          <Icon
            width="12px"
            height="12px"
            view-box="0 0 284.929 284.929"
            class="transform rotate-180"
          >
            <Chevron />
          </Icon>
        </span>
      </li>

      <li class="px-2">
        <button
          v-if="canGoToEnd"
          type="button"
          class="focus:outline-none text-gray-600"
          aria-label="Go to last page"
          @click="goTo(totalPages)"
        >
          <Icon
            width="12px"
            height="12px"
            view-box="0 0 284.929 284.929"
            class="transform rotate-180"
          >
            <Chevron double />
          </Icon>
        </button>
        <span
          v-else-if="!canGoToEnd"
          class="text-gray-400"
        >
          <Icon
            width="12px"
            height="12px"
            view-box="0 0 284.929 284.929"
            class="transform rotate-180"
          >
            <Chevron double />
          </Icon>
        </span>
      </li>
    </ul>
    <p class="text-sm text-center">
      Showing {{ pagination.from }} to {{ toCount }} of {{ pagination.total }}
    </p>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Chevron from '@components/Icons/Chevron'

export default {
  components: {
    Icon,
    Chevron
  },

  props: {
    pagination: {
      type: Object,
      required: true
    },
    maxVisibleButtons: {
      type: Number,
      default: 3
    }
  },

  computed: {
    /**
     * Returns the number of the last entry on the current page
     *
     * e.g. Showing X to Y – this returns Y
     *
     * @return {Number}
     */
    toCount() {
      if (this.pagination.total < this.pagination.to) {
        return this.pagination.total
      }
      return this.pagination.to
    },

    /**
     * @return {Number}
     */
    currentPage() {
      return this.pagination.current_page
    },

    /**
     * @return {Number}
     */
    from() {
      return this.pagination.from
    },

    /**
     * @return {Number}
     */
    to() {
      return this.pagination.to
    },

    /**
     * @return {Number}
     */
    totalPages() {
      return Math.ceil(this.pagination.total / this.perPage)
    },

    /**
     * @return {Number}
     */
    perPage() {
      return this.pagination.per_page
    },

    /**
     * @return {Number}
     */
    startPage() {
      if (this.currentPage === 1) {
        return 1
      }

      if (this.currentPage === this.totalPages) {
        if (this.maxVisibleButtons > this.totalPages) {
          // If we only have 1 or 2 pages, the start page is page 1
          return 1
        }
        return this.totalPages - this.maxVisibleButtons + 1
      }

      return this.currentPage - 1
    },

    /**
     * @return {Number}
     */
    endPage() {
      return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages)
    },

    /**
     * @return {Array}
     */
    pages() {
      const range = []
      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          name: i,
          isCurrentPage: i === this.currentPage
        })
      }
      return range
    },

    /**
     * @return {Boolean}
     */
    canGoToStart() {
      return this.currentPage > 1
    },

    /**
     * @return {Boolean}
     */
    canGoBack() {
      return this.currentPage > 1
    },

    /**
     * @return {Boolean}
     */
    canGoForward() {
      return this.currentPage < this.totalPages
    },

    /**
     * @return {Boolean}
     */
    canGoToEnd() {
      return this.currentPage < this.totalPages
    }
  },

  methods: {
    /**
     * @param {Number} pageNumber
     */
    goTo(pageNumber) {
      this.$emit('goToPage', pageNumber)
    }
  }
}
</script>
