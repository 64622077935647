<template>
  <div>
    <ol
      v-if="questionsAndAnswers.length"
      class="divide-y divide-dashed space-y-6 -mt-6 p-6"
    >
      <li
        v-for="question in questionsAndAnswers"
        :key="question.uuid"
      >
        <h4
          class="block text-lg font-medium text-gray-700 my-6"
        >
          {{ question.question }}
        </h4>

        <p class="text-gray-600 max-w-3xl">
          {{ question.body }}
        </p>
      </li>
    </ol>
    <ResultsEmpty
      v-else
      title="No answers yet"
      description="Q&amp;A data will appear here"
    />
  </div>
</template>

<script>
import ResultsEmpty from '@components/Candidates/ResultsEmpty'

export default {
  components: {
    ResultsEmpty
  },

  props: {
    job: {
      type: Object,
      default: null
    },
    candidate: {
      type: Object,
      default: null
    }
  },

  computed: {
    questionsAndAnswers() {
      if (this.job) {
        const answers = this.candidate.personalAnswers.filter(answer => this.job.personalQuestions.find(question => answer.personalQuestionUuid === question.uuid))

        return answers.map(answer => {
          const question = this.job.personalQuestions.find(question => question.uuid === answer.personalQuestionUuid)
          return {
            ...answer,
            question: question.body
          }
        })
      } else {
        // We display all the candidate's personal answers on their
        // employer-facing profile page
        return this.candidate.personalAnswers.map(answerObject => {
          return {
            body: answerObject.body,
            question: answerObject.personalQuestionBody
          }
        })
      }
    }
  }
}
</script>
