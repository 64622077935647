<template>
  <div class="bg-white">
    <header class="border-b">
      <div class="p-6">
        <h2 class="font-medium text-xl">
          Move candidates to another pipeline stage
        </h2>
      </div>
    </header>
    <div class="p-6">
      <div class="mb-8">
        <a
          href="javascript:;"
          @click="expand = !expand"
        >
          <span :class="{'text-gray-600' : expand }">Candidates
            <span
              v-if="candidateLimitForExpand"
              class="text-xs"
            >({{ candidates.length }})</span>
          </span>
          <Icon
            v-if="candidateLimitForExpand"
            view-box="0 0 284.929 284.929"
            class="ml-2 w-2 h-2 transform -rotate-90"
          >
            <Chevron />
          </Icon>
        </a>

        <transition
          enter-active-class="transition-all duration-500 ease-out"
          leave-active-class="transition-all duration-150 ease-in"
          enter-class="opacity-0"
          leave-class="opacity-100"
          enter-to-class="opacity-100"
          leave-to-class="opacity-0"
        >
          <ul
            v-if="isOpen"
            class="mt-1 text-sm flex flex-wrap -mx-2"
          >
            <li
              v-for="(candidate, index) in candidates"
              :key="index"
              class="p-2"
            >
              <button
                type="button"
                class="inline-flex items-center px-3 py-0.5 rounded-lg text-sm bg-gray-200 hover:bg-gray-300 duration-150 ease-out"
                @click="$emit('remove-candidate', candidate)"
              >
                <span class="mr-2">{{ candidate.name }}</span>
                <svg
                  class="h-2 w-2"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 8 8"
                >
                  <path
                    stroke-linecap="round"
                    stroke-width="1.5"
                    d="M1 1l6 6m0-6L1 7"
                  />
                </svg>
              </button>
            </li>
          </ul>
        </transition>
      </div>

      <fieldset>
        <legend class="sr-only">
          Move to stage
        </legend>

        <h3>Stage</h3>

        <div class="bg-white rounded-md -space-y-px mt-4">
          <label
            v-for="(stage, index) in pipeline"
            :key="index"
            :class="[index === 0 ? 'rounded-tl-lg rounded-tr-lg' : '', index === pipeline.length - 1 ? 'rounded-bl-lg rounded-br-lg' : '', option === stage.slug ? 'bg-gray-200 border-gray-200' : 'border-gray-200', 'relative border p-4 flex cursor-pointer focus:outline-none']"
          >
            <input
              v-model="option"
              type="radio"
              name="stage"
              :value="stage.slug"
              class="form-radio h-4 w-4 mt-0.5 cursor-pointer text-secondary border-gray-300 focus:ring-secondary"
              :aria-labelledby="`move-to-stage-${stage.slug}-label`"
              :aria-describedby="`move-to-stage-${stage.slug}-description`"
            >
            <div class="ml-3 flex flex-col">
              <span
                id="move-to-stage-0-label"
                class="block"
              >
                {{ stage.name }}
              </span>
            </div>
          </label>
        </div>
      </fieldset>

      <div class="flex mt-12">
        <BaseButton
          variant="ghost"
          class="md:w-24 mr-4"
          @click="$emit('cancel')"
        >
          Cancel
        </BaseButton>
        <BaseButton
          variant="green"
          :disabled="!candidates.length"
          @click="$emit('move-to-stage', { stageSlug: option })"
        >
          Move candidates
        </BaseButton>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Chevron from '@components/Icons/Chevron'

import { mapGetters } from 'vuex'

export default {
  components: {
    Icon,
    Chevron
  },

  props: {
    candidates: {
      type: Array,
      default: () => []
    },

    pipeline: {
      type: Array,
      default: () => []
    },

    stage: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      option: this.stage.slug,
      expand: false
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    }),

    candidateLimitForExpand() {
      if (!this.candidates.length) {
        return 0
      }
      return this.candidates.length > 25
    },

    isOpen() {
      return this.candidateLimitForExpand ? this.expand : !!this.candidates.length
    }
  }
}
</script>
