<template>
  <div class="rounded-lg bg-white shadow p-6">
    <h2 class="text-lg font-medium mb-8">
      Skills
    </h2>
    <ul
      v-if="skills.length"
      class="mt-1 text-sm flex flex-wrap -mx-2"
    >
      <li
        v-for="(skill, index) in skills"
        :key="index"
        class="p-2"
      >
        <span class="inline-flex items-center px-3 py-0.5 rounded-lg text-sm font-medium bg-gray-200 text-gray-800">
          {{ skill }}
        </span>
      </li>
    </ul>
    <div
      v-else
      class="border-2 border-dashed border-gray-200 rounded-lg h-32 text-gray-400 text-sm flex flex-col justify-center items-center"
    >
      <p>Incomplete</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    skills: {
      type: Array,
      default: null
    }
  }
}
</script>
