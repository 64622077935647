<template>
  <div
    v-if="!$usesFeature('classic-jobs')"
    class="relative"
  >
    <transition
      enter-active-class="transition-all duration-500 ease-out"
      leave-active-class="transition-all duration-150 ease-in"
      enter-class="opacity-0"
      leave-class="opacity-100"
      enter-to-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <JobProfileEnhanced
        v-if="$usesFeature('candidate-wizard')"
        :job-profile="jobProfile"
        class="px-6"
        @job-updated="fetchJob()"
      />
    </transition>
  </div>
  <BaseContainer v-else-if="$usesFeature('classic-jobs')">
    <AssessmentShow />
  </BaseContainer>
</template>

<script>
import jobsApi from '@api/jobs'
import AssessmentShow from '@components/ClientAssessments/AssessmentShow'
import JobProfileEnhanced from '@components/Jobs/JobProfileEnhanced'

export default {
  components: {
    AssessmentShow,
    JobProfileEnhanced
  },

  data() {
    return {
      jobProfile: null
    }
  },

  created() {
    this.fetchJob()
  },

  methods: {
    fetchJob() {
      this.jobProfile = null

      return jobsApi.job(this.$route.params.id)
        .then(jobProfile => {
          this.jobProfile = jobProfile
        })
        .catch(error => {
          this.jobProfile = null
          throw error
        })
    }
  }
}
</script>
