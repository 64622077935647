<template>
  <Tabs
    :tabs="tabs"
    :active-tab="activeTab"
    @switchToTab="switchToTab($event)"
  />
</template>

<script>
import Tabs from '@components/CandidateWizardProfile/Tabs'

import { mapGetters } from 'vuex'

export default {
  components: {
    Tabs
  },

  props: {
    activeTab: {
      type: String,
      default: 'aptitude'
    }
  },

  computed: {
    ...mapGetters({
      maskedAttributes: 'organisations/maskedAttributes'
    }),

    /**
     * @return {Array}
     */
    tabs() {
      let tabs = [
        {
          id: 'aptitude',
          name: 'Aptitude'
        },
        {
          id: 'skills',
          name: 'Skills'
        },
        {
          id: 'drives',
          name: 'Drives'
        },
        {
          id: 'personality',
          name: 'Personality'
        },
        {
          id: 'customTests',
          name: 'Custom Tests'
        },
        {
          id: 'qa',
          name: 'Q&A'
        }
      ]

      if (this.maskedAttributes.includes('profileTab')) {
        tabs.push({
          id: 'profile',
          name: 'Profile'
        })
      }

      return tabs
    }
  },

  methods: {
    /**
     * @param {string} tab
     */
    switchToTab(tab) {
      this.$emit('switchToTab', tab)
    }
  }
}
</script>
