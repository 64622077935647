<template>
  <ul
    class="flex flex-col items-center justify-center mx-auto mt-3 text-xs text-gray-600 sm:flex-row sm:space-x-5 md:justify-between"
  >
    <li class="flex item-center">
      <span>Webcam enabled</span>
      <span class="ml-1">
        <Check
          v-if="webcam.includes(true)"
          class="w-4 h-4 text-gray-600"
        />
        <Cross
          v-else
          class="w-4 h-4 text-gray-600"
        />
      </span>
    </li>
    <li class="flex item-center">
      <span>Full screen always active</span>
      <span class="ml-1">
        <Check
          v-if="hasExitedFullScreen.includes(false)"
          class="w-4 h-4 text-gray-600"
        />
        <Cross
          v-else
          class="w-4 h-4 text-gray-600"
        />
      </span>
    </li>
    <li class="flex item-center">
      <span>Mouse always in test window</span>
      <span class="ml-1">
        <Check
          v-if="hasLeaveAssessmentWindowCount"
          class="w-4 h-4 text-gray-600"
        />
        <Cross
          v-else
          class="w-4 h-4 text-gray-600"
        />
      </span>
    </li>
    <li class="flex item-center">
      <span>Location remained the same</span>
      <span class="ml-1">
        <Cross
          v-if="ipAddressChanged.includes(true)"
          class="w-4 h-4 text-gray-600"
        />
        <Check
          v-else
          class="w-4 h-4 text-gray-600"
        />
      </span>
    </li>
  </ul>
</template>

<script>
import Check from '@components/Icons/Isolated/Check'
import Cross from '@components/Icons/Isolated/Cross'

export default {
  components: {
    Check,
    Cross
  },

  props: {
    candidate: {
      type: Object,
      default: null
    }
  },

  computed: {
    hasExitedFullScreen() {
      return this.candidate.attempts.map(attempt => attempt.hasExitedFullScreen)
    },

    hasLeaveAssessmentWindowCount() {
      const mouseMoves = this.candidate.attempts.map(attempt => attempt.leaveAssessmentWindowCount)

      return mouseMoves.some(move => {
        return move > 0
      })
    },

    webcam() {
      return this.candidate.attempts.map(attempt => attempt.webcam)
    },

    ipAddressChanged() {
      return this.candidate.attempts.map(attempt => attempt.ipAddressChanged)
    }
  }

}
</script>
