<template>
  <div class="rounded-lg bg-white shadow p-6">
    <h2 class="text-lg font-medium mb-8">
      Role Experience
    </h2>
    <ul
      v-if="roleExperience.length"
      class="space-y-6"
    >
      <li
        v-for="(role, index) in roleExperience"
        :key="index"
        class="max-w-sm text-sm"
      >
        <div class="flex justify-between mb-4">
          <span class="truncate">
            {{ role.name }}
          </span>
          <span :class="['w-32 text-right', !roleHasYears ? 'text-xs' : 'text-sm']">
            <strong v-if="roleHasYears">{{ role.years }}</strong> {{ roleHasYears ? 'years' : 'Less than year' }}
          </span>
        </div>
        <div class="relative">
          <span class="block w-full bg-gray-200 rounded-full h-2"></span>
          <span
            class="block bg-primary rounded-full h-2 absolute inset-0"
            :style="`width: ${role.years * 100 / 50}%`"
          ></span>
        </div>
      </li>
    </ul>
    <div
      v-else
      class="border-2 border-dashed border-gray-200 rounded-lg h-32 text-gray-400 text-sm flex flex-col justify-center items-center"
    >
      <p>Incomplete</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    roleExperience: {
      type: Array,
      default: null
    }
  },

  methods: {
    /**
     * @return {Boolean}
     */
    roleHasYears(years) {
      return years !== 0
    }
  }
}
</script>
