/**
 * Generate array of years
 *
 * @param  {integer} extraYears
 * @return {array}
 */
export const generatedYears = extraYears => {
  let endYear = new Date().getFullYear()
  if (Number.isInteger(extraYears)) {
    endYear = endYear + extraYears
  }
  let years = []
  let startYear = 1970

  while (startYear <= endYear) {
    years.push(startYear++)
  }

  return years.reverse().map(year => {
    return { text: year, value: year }
  })
}

export const monthNumToName = (monthNum) => {
  const monthDateObj = new Date(2000, monthNum - 1, 1)
  const locale = navigator.language
  return monthDateObj.toLocaleString(locale, { month: 'long' })
}

/**
 * Generate array of months
 *
 * @return {array}
 */
export const generatedMonths = () => {
  const months = new Array(12).fill(1).map((x, y) => x + y)
  return months.map(month => {
    return { text: monthNumToName(month), value: month }
  })
}
