/**
 * @return {String}
*/
export default function(percentageBand, scorePercentage, target = 'text') {
  if (typeof percentageBand === 'undefined') {
    return '—'
  }

  const colors = {
    lowest: `${target}-red-500`,
    low: `${target}-red-500`,
    medium: `${target}-amber`,
    high: `${target}-secondary`,
    highest: `${target}-secondary`
  }

  let colorBand

  if (percentageBand === null) {
    if (scorePercentage <= 40) {
      colorBand = `${target}-red-500`
    } else if ((scorePercentage => 41) && (scorePercentage <= 69)) {
      colorBand = `${target}-amber`
    } else {
      colorBand = `${target}-secondary`
    }
  } else {
    colorBand = colors[percentageBand]
  }

  return colorBand
}
