<template>
  <div>
    <div class="items-center justify-between p-6 sm:flex">
      <div class="flex items-center">
        <Icon
          view-box="0 0 304 304"
          fill="none"
          class="w-12 h-12"
        >
          <component
            :is="icon"
          />
        </Icon>
        <div class="ml-4">
          <h2 class="text-xl sm:text-2xl">
            {{ exam.name }}
          </h2>
          <p class="text-sm text-gray-600 capitalize">
            {{ exam.level }}
          </p>
        </div>
      </div>

      <a
        v-if="$usesFeature('classic-jobs')"
        href="javascript:;"
        class="flex items-end mt-8 text-sm sm:mt-0"
        @click="toggleAccordion"
      >
        <span>{{ accordionButtonText }} results</span>
        <Icon
          view-box="0 0 24 24"
          fill="none"
          :class="['w-6 h-6 transform duration-300 ease-in-out', accordionOpen ? 'rotate-0' : 'rotate-180']"
        >
          <path d="M8.7 13.7a1 1 0 1 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 10.42l-3.3 3.3z"></path>
        </Icon>
      </a>
    </div>

    <transition
      enter-active-class="transition-all duration-150 ease-out"
      leave-active-class="transition-all duration-150 ease-in"
      enter-class="opacity-0"
      leave-class="opacity-100"
      enter-to-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="accordionOpen">
        <div class="p-6 text-gray-600 border-t border-gray-300">
          <p class="text-sm sm:w-2/3 sm:mb-0 sm:mr-4">
            <template v-if="exam.slug === 'interest-inventory-dceb7'">
              Based on the RIASEC model, a profile of a candidate’s preferred work activities and environments is
              summarized across six dimensions.
            </template>
            <template v-else>
              Our state-of-the-art HEXACO personality test breaks down a candidate’s personality and scores them across 24
              work-related traits critical to the workplace.
            </template>
          </p>
        </div>
        <ul
          v-show="accordionOpen"
          class="w-full p-6 space-y-12 text-gray-600"
        >
          <li
            v-for="(trait, index) in traitsSortedByPercentile"
            :key="`${index}-${exam.attempt.createdAt}`"
          >
            <div class="flex items-center justify-between leading-none">
              <p class="w-56 mr-2 text-lg font-medium text-gray-800 capitalize">
                {{ trait.name }}
              </p>

              <div class="w-full h-2 mx-4 bg-gray-300">
                <div
                  :class="['h-full bg-gray-700']"
                  :style="`width: ${trait.percentile}%`"
                >
                </div>
              </div>
              <div class="flex flex-col items-center w-56">
                <span :class="['text-xs text-white font-medium uppercase tracking-wider p-1 rounded leading-none', setBandColor(trait.band)]">{{ trait.band }}</span>
                <div class="flex items-center mt-1">
                  <span class="text-3xl text-gray-800">{{ trait.percentileLabel }}</span>
                </div>
                <span class="my-1 text-xs font-medium text-gray-500">Percentile</span>
              </div>
            </div>

            <div class="justify-between mt-6 space-y-4 sm:flex sm:space-x-12 sm:space-y-0">
              <p class="flex-1 p-4 bg-gray-100">
                {{ trait.description }}
              </p>
              <article class="relative flex-1 px-6 sm:px-0">
                <Icon
                  view-box="0 0 100 100"
                  class="absolute inset-0 w-10 h-10 opacity-25 text-secondary"
                >
                  <Quote />
                </Icon>
                <div class="mt-4 sm:ml-6">
                  <p class="mb-2 text-xs font-medium tracking-wider text-gray-800 uppercase">
                    Suggested interview question
                  </p>
                  <p class="text-lg">
                    {{ trait.question }}
                  </p>
                </div>
              </article>
            </div>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>

import Icon from '@components/Icons/Icon'
import Quote from '@components/Icons/Quote'

export default {
  components: {
    Icon,
    Quote
  },

  props: {
    icon: {
      type: Function,
      default: null
    },
    exam: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      accordionOpen: true,
      maxScore: 20
    }
  },

  computed: {
    /**
     * @return {Boolean}
     */
    accordionButtonText() {
      return this.accordionOpen ? 'Hide' : 'View'
    },

    traitsSortedByPercentile() {
      if (!this.exam) {
        return []
      }

      let sortedTraits
      // We use slice() here so we dont mess with the original array
      sortedTraits = this.exam.attempt.summary.slice().sort((a, b) => b.percentile - a.percentile)

      if (this.exam.slug === 'personality-2021-11') {
        const names = {
          'Communication': 'Outgoingness',
          'Diligence': 'Detail Orientation',
          'Sensitivity': 'Empathy',
          'Teamwork': 'Team Orientation'
        }
        const traitsToHide = [
          'Decisiveness',
          'Initiative',
          'Persuasiveness',
          'Self-Confidence'
        ]

        const traitsToDisplay = sortedTraits.filter(trait => !traitsToHide.includes(trait.name))

        sortedTraits = traitsToDisplay.map(trait => {
          return {
            ...trait,
            name: names[trait.name] || trait.name
          }
        })
      }

      return sortedTraits
    }
  },

  methods: {
    /**
     * @return {Number}
     */
    calculatePercentage(score) {
      return score * 100 / this.maxScore
    },

    /**
     * @return {String}
     */
    setBandColor(band) {
      const bandName = band.toLowerCase()
      const bands = {
        'low': 'bg-red-500',
        'medium': 'bg-amber',
        'high': 'bg-secondary'
      }
      return bands[bandName]
    },

    /**
     * @return {Boolean}
     */
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen
    }
  }
}
</script>
