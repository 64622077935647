<template>
  <div class="rounded-lg bg-white shadow p-6">
    <h2 class="text-lg font-medium mb-8">
      Employment Preferences
    </h2>
    <div class="space-y-8 -mt-8">
      <dl class="grid grid-cols-1 gap-y-8 sm:grid-cols-2 pt-8 sm:divide-x divide-gray-300 divide-dashed">
        <div class="sm:col-span-1 sm:pr-4">
          <dt
            v-if="employmentStatus"
            class="text-sm font-medium text-gray-500"
          >
            Job Search Status
          </dt>
          <dd
            v-if="employmentStatus && employmentStatus.availability"
            class="mt-1 text-sm text-gray-800"
          >
            {{ employmentStatus.availability }}
          </dd>
          <div
            v-else
            class="border-2 border-dashed border-gray-200 rounded-lg h-32 text-gray-400 text-sm flex flex-col justify-center items-center"
          >
            <p>Incomplete</p>
          </div>
        </div>

        <div
          class="sm:col-span-1 sm:pl-4"
        >
          <dt
            v-if="atLeastOneOptionChosen"
            class="text-sm font-medium text-gray-500"
          >
            Type
          </dt>
          <dd
            v-if="atLeastOneOptionChosen"
            class="mt-1 text-sm text-gray-800 space-y-2"
          >
            <div v-if="employment.permanent.preference && employment.permanent.minPay !== 0">
              Permanent: <span class="font-bold">£{{ employment.permanent.minPay }}</span>
            </div>
            <div v-if="employment.contract.preference && employment.contract.minPay !== 0">
              Contract: <span class="font-bold">£{{ employment.contract.minPay }}</span>
            </div>
            <div v-if="employment.internship.preference">
              Internship
            </div>
          </dd>
          <dd
            v-else
            class="border-2 border-dashed border-gray-200 rounded-lg h-32 text-gray-400 text-sm flex flex-col justify-center items-center"
          >
            <p>Incomplete</p>
          </dd>
        </div>
      </dl>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    employmentStatus: {
      type: Object,
      default: null
    },

    employment: {
      type: Object,
      default: null
    }
  },

  computed: {
    atLeastOneOptionChosen() {
      if (!this.employment) {
        return
      }
      return this.employment.permanent.preference || this.employment.contract.preference || this.employment.internship.preference
    }
  }
}
</script>
