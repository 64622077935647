<template>
  <form
    class="w-full"
    @submit.prevent="search()"
  >
    <label
      for="search"
      class="block text-sm font-medium text-gray-700"
    >
      Search candidates
    </label>

    <div class="mt-1 flex rounded-lg">
      <div class="relative flex items-stretch flex-grow focus-within:z-10">
        <input
          id="text"
          v-model="query"
          type="text"
          name="search"
          class="form-input rounded w-full rounded-tr-none rounded-br-none pr-10"
          placeholder="Search candidates"
          @keyup="showClearSearch = true"
        >
        <span
          v-if="showClearSearch"
          @click="clearSearch"
        >
          <Icon
            view-box="0 0 14 14"
            class="text-gray-500 transform rotate-45 w-3 h-3 cursor-pointer absolute inset-y-0 right-4 h-full"
          >
            <Plus />
          </Icon>
        </span>
      </div>
      <button
        class="-ml-px relative inline-flex items-center space-x-2 px-4 py-2 border border-gray-300 text-sm font-medium rounded-r-lg text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none"
        @click="search()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        <span class="sr-only">Search</span>
      </button>
    </div>
  </form>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Plus from '@components/Icons/Plus'

export default {
  components: {
    Icon,
    Plus
  },

  props: {
    placeholder: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      query: '',
      showClearSearch: false
    }
  },

  watch: {
    query(val) {
      if (!val) {
        this.search()
      }
    }
  },

  methods: {
    search() {
      this.$emit('search', this.query)
    },

    clearSearch() {
      this.query = ''
      this.showClearSearch = false
      this.search()
    }
  }
}
</script>
