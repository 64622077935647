<template>
  <div @click="disqualifyCandidates">
    <Icon
      icon="Bin"
      view-box="0 0 24 24"
      class="w-6 h-6 fill-none hover:text-secondary"
    >
      <g>
        <circle
          cx="12"
          cy="8"
          r="3.25"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
        ></circle>
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M12.25 19.25H6.94953C5.77004 19.25 4.88989 18.2103 5.49085 17.1954C6.36247 15.7234 8.23935 14 12.25 14"
        ></path>
        <path
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="1.5"
          d="M19.25 17L15.75 17"
        ></path>
      </g>
    </Icon>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import organisationsApi from '@api/organisations'

import { mapGetters } from 'vuex'

export default {
  components: {
    Icon
  },

  props: {
    candidates: {
      type: Array,
      default: null
    },
    job: {
      type: Object,
      default: null
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    })
  },

  methods: {
    disqualifyCandidates() {
      const candidateIds = this.candidates.map(candidate => candidate.id)

      return organisationsApi.disqualifyCandidates(
        this.organisationId,
        this.job.uuid,
        { candidates: candidateIds }
      )
        .then(candidates => {
          this.$emit('candidates-disqualified')
        })
        .catch(error => {
          throw error
        })
    }
  }
}
</script>
