<template>
  <div class="overflow-y-auto">
    <ul class="divide-y border-t border-gray-300 flex-1">
      <li
        v-for="(item, itemIdx) in items"
        :key="itemIdx"
      >
        <slot
          name="item"
          :item="item"
        />
      </li>
    </ul>

    <Loader
      v-if="loading"
      class="py-6"
    />

    <div
      v-if="items.length"
      v-observe-visibility="handleScrollToBottom"
    >
    </div>
  </div>
</template>

<script>
import Loader from '@components/Loader'

export default {
  components: {
    Loader
  },

  props: {
    items: {
      type: Array,
      default: () => []
    },

    lastPage: {
      type: Number,
      default: 1
    },

    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      page: 1
    }
  },

  methods: {
    handleScrollToBottom(isVisible) {
      if (!isVisible) {
        return
      }

      if (this.page >= this.lastPage) {
        return
      }

      this.page++

      this.$emit('fetchPage', this.page)
    }
  }
}
</script>
