<template>
  <div
    class="relative z-35"
  >
    <button
      ref="button"
      type="button"
      :class="['focus:outline-none', { 'pointer-events-none opacity-50': disabled }]"
      :disabled="disabled"
      @click="open"
    >
      <slot
        name="button"
        :isOpen="isOpen"
      >
        <span>Select...</span>
      </slot>
    </button>

    <transition
      enter-active-class="transition-all duration-100 ease-out-quad"
      leave-active-class="transition-all duration-150 ease-in-quad"
      enter-class="opacity-0 transform scale-75"
      enter-to-class="opacity-100 transform scale-100"
      leave-class="opacity-100 transform scale-100"
      leave-to-class="opacity-0 transform scale-75"
    >
      <div
        v-if="isOpen"
      >
        <button
          type="button"
          class="z-30 block fixed inset-0 w-full h-full cursor-default"
          @click="isOpen = false"
        ></button>

        <div :class="['absolute z-40', align === 'right' ? 'right-0' : 'left-0']">
          <div class="mt-3 bg-white w-64 p-2 shadow-lg text-left rounded-lg border border-gray-300 space-y-2 flex flex-col">
            <div
              v-if="searchActive"
              class="flex flex-shrink-0"
            >
              <input
                ref="search"
                v-model="search"
                class="form-input rounded-lg w-full border-gray-300 focus:border-gray-400"
                placeholder="Search"
                @keydown.esc="close"
                @keydown.up="highlightPrev"
                @keydown.down="highlightNext"
                @keydown.enter.prevent="selectHighlighted"
                @keydown.tab.prevent
              >
            </div>

            <slot name="content">
              <nav
                v-show="filteredOptions.length > 0"
                ref="options"
                class="max-h-64 overflow-y-auto space-y-1"
                role="listbox"
              >
                <DropdownButton
                  v-for="(option, optionIdx) in filteredOptions"
                  :key="optionIdx"
                  :loading="loading && selected === option"
                  :class="{ 'bg-gray-200': highlightedIndex === optionIdx }"
                  role="option"
                  @click="select(option)"
                  @keydown.esc="close"
                  @keydown.up="highlightPrev"
                  @keydown.down="highlightNext"
                  @mouseover="highlightedIndex = -1"
                  @keydown.enter.prevent="selectHighlighted"
                  @keydown.tab.prevent
                >
                  <template
                    slot="buttonContent"
                    :option="option"
                    :loading="loading"
                  >
                    <slot
                      name="buttonContent"
                      :option="option"
                      :loading="loading"
                    >
                    </slot>
                  </template>
                </DropdownButton>
              </nav>
            </slot>
            <div
              v-show="searchActive && filteredOptions.length === 0"
              class="text-xs"
            >
              No results found for "{{ search }}"
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import DropdownButton from '@components/DropdownButton'

export default {
  components: {
    DropdownButton
  },

  props: {
    value: {
      type: Object,
      default: null
    },

    options: {
      type: Array,
      default: () => []
    },

    searchActive: {
      type: Boolean,
      default: false
    },

    filterFunction: {
      type: Function,
      default: null
    },

    label: {
      type: Object,
      default: null
    },

    loading: {
      type: Boolean,
      default: null
    },

    align: {
      type: String,
      default: 'left'
    },

    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isOpen: false,
      search: '',
      highlightedIndex: 0,
      selected: null
    }
  },

  computed: {
    filteredOptions() {
      if (this.filterFunction) {
        return this.filterFunction(this.search, this.options)
      } else {
        return this.options
      }
    }
  },

  watch: {
    search(query) {
      this.$emit('searching', query)
    },

    loading(val) {
      if (!val) {
        this.close()
      }
    }
  },

  methods: {
    open() {
      if (this.isOpen) {
        return
      }
      this.isOpen = true

      this.$nextTick(() => {
        if (this.searchActive) {
          this.$refs.search.focus()
        } else {
          // Handle mouseover
          if (this.highlightedIndex === -1) {
            this.$refs.options.children[0].focus()
          } else {
            this.$refs.options.children[this.highlightedIndex].focus()
          }
        }

        // @TODO - make this better
        if (this.selected) {
          this.highlightedIndex = this.options.findIndex(option => option === this.selected)
        }
        if (this.value) {
          this.highlightedIndex = this.options.findIndex(option => option.uuid === this.value.uuid)
        }

        this.scrollToHighlighted()
      })
    },

    close() {
      this.isOpen = false
      this.highlightedIndex = 0
      this.$refs.button.focus()
    },

    select(option) {
      this.selected = option
      this.$emit('input', option)
      this.search = ''

      this.$nextTick(() => {
        if (!this.loading) {
          this.close()
        }
      })
    },

    selectHighlighted() {
      this.select(this.filteredOptions[this.highlightedIndex])
    },

    scrollToHighlighted() {
      if (this.highlightedIndex === -1) {
        this.$refs.options.children[0].scrollIntoView({
          block: 'nearest'
        })
      } else {
        this.$refs.options.children[this.highlightedIndex].scrollIntoView({
          block: 'nearest'
        })
      }
    },

    highlight(index) {
      this.highlightedIndex = index

      if (this.highlightedIndex < 0) {
        this.highlightedIndex = this.filteredOptions.length - 1
      }

      if (this.highlightedIndex > this.filteredOptions.length - 1) {
        this.highlightedIndex = 0
      }

      this.scrollToHighlighted()
    },

    highlightPrev() {
      this.highlight(this.highlightedIndex - 1)
    },

    highlightNext() {
      this.highlight(this.highlightedIndex + 1)
    }
  }
}
</script>
