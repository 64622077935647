<template>
  <div :id="exam.slug">
    <div :class="['p-6', {'sm:flex justify-between items-center' : layoutLarge }]">
      <div class="flex items-center">
        <div>
          <h2
            class="text-xl sm:text-2xl"
          >
            {{ exam.name }}
          </h2>
        </div>
      </div>

      <div
        :class="['sm:flex items-center mt-10', {'mt-4 sm:mt-0' : layoutLarge }]"
      >
        <ul
          class="flex justify-between items-center text-center text-sm text-gray-600 py-4 sm:py-0 mr-10"
        >
          <li class="leading-none sm:mr-4 lg:mr-10">
            <p>
              Answered
            </p>
            <p class="mt-2">
              <span class="text-lg text-gray-800 mt-2">{{ exam.attempt.questionsAnswered }}</span> / {{ exam.attempt.questionsTotal }}
            </p>
          </li>
          <li
            v-if="exam.attempt.speedCompleted"
            class="leading-none"
          >
            <p>
              Time
            </p>
            <p
              class="mt-2 text-gray-800 text-lg"
            >
              {{ secondsToHuman(exam.attempt.speedCompleted) }}
            </p>
          </li>
        </ul>
        <template v-if="exam.attempt.scorePercentage > 0">
          <div class="sm:flex justify-between items-center leading-none w-64">
            <div class="flex flex-col w-full">
              <div
                class="h-2 w-full bg-gray-300 mt-4 sm:mt-0"
              >
                <div
                  :class="['h-full bg-secondary']"
                  :style="`width: ${exam.attempt.scorePercentage}%`"
                >
                </div>
              </div>
              <p :class="['text-xs tracking-wider mt-3', scoreColor(exam.attempt.percentageBand)]">
                {{ exam.attempt.bracket }}
              </p>
            </div>
            <div class="flex items-center w-24 mt-4 sm:mt-0 sm:ml-4">
              <span class="text-5xl">{{ exam.attempt.scorePercentage }}</span><span class="text-lg text-gray-600 ml-1">%</span>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div
      v-if="exam.averageScorePercentage"
      :class="['text-gray-600 border-t border-gray-300 p-6', {'md:flex md:justify-between' : layoutLarge }]"
    >
      <template>
        <div :class="['bg-gray-100 p-4 mb-6 md:mb-0', {'md:w-2/3 md:w-2/3 md:mr-6' : layoutLarge }]">
          <div class="sm:flex items-center justify-between mb-4">
            <h3 class="text-gray-800 font-medium text-xl">
              Performance
            </h3>
            <ul class="sm:flex items-center justify-between my-2 sm:my-0">
              <li class="sm:mr-6 flex items-center sm:justify-between">
                <span class="inline-flex bg-graph-secondary w-6 h-2 mr-2"></span>
                <p class="text-sm">
                  Candidate
                </p>
              </li>
              <li class="flex items-center sm:justify-between">
                <span class="inline-flex bg-gray-primary w-6 h-2 mr-2"></span>
                <p class="text-sm">
                  Average
                </p>
              </li>
            </ul>
          </div>
          <DistributionCurveGraph
            :exam="exam"
            :index="index"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { secondsToHuman } from '@utils/time'
import scoreColor from '@utils/scoreColor'
import DistributionCurveGraph from '@components/Candidates/DistributionCurveGraph'

import { mapGetters } from 'vuex'

export default {
  components: {
    DistributionCurveGraph
  },

  props: {
    candidate: {
      type: Object,
      default: null
    },
    exam: {
      type: Object,
      default: null
    },
    index: {
      type: Number
    },
    layout: {
      type: String,
      default: 'large'
    }
  },

  data() {
    return {
      secondsToHuman,
      scoreColor
    }
  },

  computed: {
    layoutLarge() {
      return this.layout === 'large'
    },

    ...mapGetters({
      maskedAttributes: 'organisations/maskedAttributes'
    })
  },

  methods: {
    /**
     * @param {Number} score
     * @param {string} percentageBand
     * @return {string}
     */
    parseNarrative(score, percentageBand) {
      let examNarrative = this.exam.narratives[percentageBand]

      if (!examNarrative) {
        return
      }

      const strings = {
        '[name]': this.maskedAttributes.includes('name') ? `<strong class="text-xl">Anonymous</strong> ` : `<strong class="text-xl">${this.candidate.firstName} ${this.candidate.surname}</strong>`,
        '[score]': `<strong class="text-xl">${this.exam.attempt.scorePercentage}%</strong>`,
        '[global average]': `<strong class="text-xl">${this.exam.averageScorePercentage}%</strong>`
      }

      examNarrative = examNarrative.replace(/\[name\]|\[score\]|\[global average\]/gi, matched => {
        return strings[matched]
      })

      return examNarrative
    },

    /**
     * @param {string} str
     * @return {string}
     */
    parseStrong(str) {
      if (typeof str !== 'string') {
        return
      }

      str = str.replace(/\[/g, '<strong class="text-xl">')
      str = str.replace(/\]/g, '</strong>')
      return str
    }
  }
}
</script>
