<template>
  <div
    class="w-full max-w-screen-xl mx-auto"
  >
    <div
      class="h-fixed-content flex flex-col"
    >
      <header
        class="py-8"
      >
        <div class="sm:flex sm:items-center mb-8">
          <JobsDropdownButton
            v-if="jobs && selectedJob"
            v-model="selectedJob.job"
            :jobs="jobs"
            @input="jobChanged($event)"
          />

          <div class="flex ml-auto items-center space-x-6 mt-4 sm:mt-0">
            <EditJobButton
              :job="job"
              @jobUpdated="$emit('job-updated')"
            >
              <span>Edit Job</span>
            </EditJobButton>

            <InviteCandidatesButton
              :job="job"
            />
          </div>
        </div>

        <PipelineNavigation
          :pipeline="pipeline"
          :chosen-stage="chosenStage"
          :candidate-count="candidateCount"
          @chosen-stage="filterCandidatesByStage($event)"
        />

        <SlideOverPanelTrigger class="md:hidden mt-8 inline-flex">
          <template v-slot:text>
            <BaseButton>
              View Candidates
            </BaseButton>
          </template>

          <template v-slot:panelHeading>
            <h2
              id="slide-over-title"
              class="text-lg font-medium text-gray-900"
            >
              Candidates
            </h2>
          </template>

          <template v-slot:default>
            <CandidatesActionList
              v-model="candidatesQuery.filters"
              class="mb-8 lg:mb-0 lg:max-w-sm"
              :candidates="candidates"
              :candidate-count="candidateCount"
              :stage="chosenStage"
              :viewing-candidate="selectedCandidate"
              :last-page="candidatesLastPage"
              :loading="loading"
              @fetchCandidate="fetchCandidate($event)"
              @fetchCandidates="candidatesQuery.page = $event"
              @search="candidatesQuery.search = $event"
              @sort="candidatesQuery = {...candidatesQuery, ...$event}"
              @candidatesQualifiedFilter="candidatesQualifiedFilter = $event"
              @move-to-stage="candidatesToMove = $event"
              @qualification-status-update="qualificationStatusUpdate($event)"
              @delete-candidates="deleteCandidates($event)"
            />
          </template>
        </SlideOverPanelTrigger>
      </header>

      <div
        class="flex-1 flex lg:overflow-hidden"
      >
        <main class="flex-1 lg:flex">
          <CandidatesActionList
            v-model="candidatesQuery.filters"
            class="hidden lg:block mb-8 lg:mb-0 lg:max-w-sm"
            :candidates="candidates"
            :candidate-count="candidateCount"
            :stage="chosenStage"
            :viewing-candidate="selectedCandidate"
            :last-page="candidatesLastPage"
            :loading="loading"
            @fetchCandidate="fetchCandidate($event)"
            @fetchCandidates="candidatesQuery.page = $event"
            @search="candidatesQuery.search = $event"
            @sort="candidatesQuery = {...candidatesQuery, ...$event}"
            @candidatesQualifiedFilter="candidatesQualifiedFilter = $event"
            @move-to-stage="candidatesToMove = $event"
            @qualification-status-update="qualificationStatusUpdate($event)"
            @delete-candidates="deleteCandidates($event)"
          />

          <div
            class="flex-1 flex flex-col overflow-y-auto lg:ml-8 bg-gray-100 rounded-lg shadow relative"
          >
            <transition
              mode="out-in"
              enter-active-class="transition-all duration-500 ease-out"
              leave-active-class="transition-all duration-150 ease-in"
              enter-class="opacity-0"
              leave-class="opacity-100"
              enter-to-class="opacity-100"
              leave-to-class="opacity-0"
            >
              <div
                v-if="selectedCandidate && !candidatesToMove.length && !loading"
                key="1"
                class="flex flex-col"
              >
                <div class="relative">
                  <div class="bg-white">
                    <div class="sm:flex sm:items-center sm:justify-between px-4 py-2 border-b border-gray-300 space-y-4 sm:space-y-0">
                      <span
                        v-if="candidatesQualifiedFilter === 'disqualified_only'"
                        class="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-red-100 text-red-800"
                      >
                        Disqualified
                      </span>
                      <ProfileActions
                        class="justify-center ml-auto text-sm"
                        :candidate="selectedCandidate"
                        :stage="chosenStage"
                        :pipeline="pipeline"
                        :filter="candidatesQualifiedFilter"
                        :job="job"
                        @update="fetchCandidatesAndJob()"
                        @move-to-stage="moveCandidatesToPipelineStage($event)"
                        @candidate-edited="fetchCandidate(selectedCandidate.id)"
                      />
                    </div>
                    <div class="sm:flex sm:items-center sm:justify-between text-center sm:text-left p-6">
                      <ProfileHeader
                        :profile="profile"
                        :candidate="selectedCandidate"
                      >
                        <template v-slot:name>
                          {{ selectedCandidate.firstName }} {{ selectedCandidate.surname }}
                        </template>
                        <template v-slot:email>
                          <p class="text-gray-600 text-xs truncate w-72 text-center mx-auto sm:text-left sm:mx-0">
                            {{ selectedCandidate.email }}
                          </p>
                        </template>
                      </ProfileHeader>
                    </div>

                    <div class="border-t border-gray-200 bg-gray-50 grid grid-cols-1 divide-y divide-gray-200 sm:grid-cols-2 sm:divide-y-0 sm:divide-x">
                      <ProfileHeaderTabs
                        :active-tab="activeTab"
                        @switchToTab="activeTab = $event"
                      />
                    </div>
                  </div>
                </div>

                <div class="p-4 flex-1 overflow-y-auto space-y-4">
                  <ProfileGrid
                    v-if="activeTab === 'profile'"
                    :profile="profile"
                  />

                  <PersonalQuestionsAnswers
                    v-if="activeTab === 'qa' && job.personalQuestions"
                    :job="job"
                    :candidate="selectedCandidate"
                    class="rounded-lg bg-white overflow-hidden shadow"
                  />

                  <CandidateResultsDetails
                    v-if="exams"
                    :active-tab="activeTab"
                    :candidate="selectedCandidate"
                    :exams="examsAndAttempts"
                    layout="small"
                  />
                </div>
              </div>

              <div
                v-if="candidatesToMove.length && !loading"
                class="relative shadow"
              >
                <MoveToPipelineStage
                  :candidates="candidatesToMove"
                  :job="job"
                  :pipeline="pipeline"
                  :stage="chosenStage"
                  @remove-candidate="removeCandidateFromMoveList($event)"
                  @move-to-stage="moveCandidatesToPipelineStage($event)"
                  @cancel="candidatesToMove = []"
                />
              </div>
            </transition>

            <Loader
              v-if="loading && !selectedCandidate && !candidatesQuery.search"
              class="absolute inset-16"
            />

            <StageEmpty
              v-if="!loading && !candidates.length && !selectedCandidate && candidatesQuery.stage_slug"
              :job="job"
              :title="`No candidates found in the ${chosenStage.name} stage`"
              class="h-full flex flex-col justify-center"
            />
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import jobsApi from '@api/jobs'
import PipelineNavigation from '@components/PipelineNavigation'
import CandidatesActionList from '@components/Jobs/CandidatesActionList'
import ProfileHeaderTabs from '@components/Candidates/ProfileHeaderTabs'
import EditJobButton from '@components/Jobs/EditJobButton'
import InviteCandidatesButton from '@components/Candidates/InviteCandidatesButton'
import ProfileActions from '@components/CandidateWizardProfile/ProfileActions'

import ProfileHeader from '@components/CandidateWizardProfile/ProfileHeader'
import ProfileGrid from '@components/CandidateWizardProfile/ProfileGrid'
import PersonalQuestionsAnswers from '@components/CandidateWizardProfile/PersonalQuestionsAnswers'

import CandidateResultsDetails from '@components/Candidates/CandidateResultsDetails'
import MoveToPipelineStage from '@components/Jobs/MoveToPipelineStage'
import Loader from '@components/Loader'
import SlideOverPanelTrigger from '@components/SlideOverPanelTrigger'
import JobsDropdownButton from '@components/Jobs/JobsDropdownButton'
import StageEmpty from '@components/Candidates/StageEmpty'

import organisationCandidatesApi from '@api/organisationCandidates'
import organisationsApi from '@api/organisations'

import { mapGetters } from 'vuex'

export default {
  components: {
    PipelineNavigation,
    CandidatesActionList,
    ProfileHeaderTabs,
    ProfileHeader,
    ProfileGrid,
    PersonalQuestionsAnswers,
    Loader,
    CandidateResultsDetails,
    MoveToPipelineStage,
    EditJobButton,
    InviteCandidatesButton,
    ProfileActions,
    SlideOverPanelTrigger,
    JobsDropdownButton,
    StageEmpty
  },

  props: {
    jobProfile: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      // UI
      activeTab: 'aptitude',
      candidatesQualifiedFilter: 'qualified_only',

      // Job
      selectedJob: {},

      // Candidates
      candidates: [],
      candidatesLastPage: 1,
      candidatesTotal: 0,
      loading: false,
      selectedCandidate: null,
      candidatesQuery: {
        page: 1,
        per_page: 25,
        search: '',
        qualified_only: true,
        stage_slug: 'assessment',
        filters: {

        }
      },

      // Actions
      candidatesToMove: [],
      moveToPipelineStage: false,
      chosenStage: {
        slug: 'assessment',
        name: 'Assessment'
      }
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId',
      haveJobsLoaded: 'jobs/haveJobsLoaded',
      jobs: 'jobs/openJobs',
      pipeline: 'organisations/pipeline',
      exams: 'organisations/exams'
    }),

    /**
     * @return {Object}
     */
    job() {
      if (!this.jobProfile) {
        return
      }
      return this.jobProfile.job
    },

    /**
     * @return {Object}
     */
    profile() {
      if (!this.selectedCandidate) {
        return
      }
      return this.selectedCandidate.profile
    },

    /**
     * @return {Object}
     */
    candidateCount() {
      if (!this.jobProfile) {
        return
      }
      return this.jobProfile.job.candidatePipelineCount
    },

    /**
     * @return {Array}
     */
    examsAndAttempts() {
      if (!this.exams) {
        return []
      }

      let examsAndAttempts = []

      this.exams.forEach(exam => {
        this.selectedCandidate.attempts
          .filter(attempt => attempt.examSlug === exam.slug)
          .forEach(attempt => {
            examsAndAttempts.push({
              ...exam,
              attempt: attempt
            })
          })
      })

      return examsAndAttempts.sort((a, b) => {
        return Object.entries(b.attempt).length !== 0 ? 1 : -1
      })
    }
  },

  watch: {
    candidatesQuery: {
      deep: true,
      handler() {
        this.fetchCandidates()
      }
    },

    jobProfile: {
      handler(jobProfile) {
        this.selectedJob = jobProfile
      }
    },

    candidatesQualifiedFilter(val) {
      // @TODO make better
      if (val === 'disqualified_only') {
        this.candidatesQuery.disqualified_only = true
        this.candidatesQuery.qualified_only = null
      } else {
        this.candidatesQuery.qualified_only = true
        this.candidatesQuery.disqualified_only = null
      }
    }
  },

  created() {
    this.fetchCandidates()

    if (!this.haveJobsLoaded) {
      this.$store.dispatch('jobs/getJobs')
    }
  },

  methods: {
    fetchCandidates() {
      this.loading = true

      // Removing the "filters" key and spreading the contents
      // of the object back in
      const { 'filters': remove, ...restOfQuery } = this.candidatesQuery
      const query = { ...remove, ...restOfQuery }

      return jobsApi.candidates(this.$route.params.id, query)
        .then(response => {
          this.loading = false

          if (response.meta) {
            this.candidatesLastPage = response.meta.last_page
            this.candidatesTotal = response.meta.total
          }

          this.handleCandidates(response.data)
        })
        .catch(error => {
          this.candidates = []
          this.loading = false
          throw error
        })
    },

    fetchCandidate(candidateId) {
      this.selectedCandidate = null
      this.activeTab = 'aptitude'

      return organisationCandidatesApi.candidate(this.organisationId, candidateId)
        .then(candidate => {
          this.selectedCandidate = candidate
        })
        .catch(error => {
          this.selectedCandidate = null
          throw error
        })
    },

    fetchCandidatesAndJob() {
      this.fetchCandidates()
      this.$emit('job-updated')
    },

    handleCandidates(candidates) {
      if (this.candidatesQuery.page !== 1 && !this.candidatesQuery.sort_by && this.candidatesQuery.stage_slug) {
        this.candidates.push(...candidates)
      } else {
        this.candidates = candidates
      }

      // Load the first candidate
      if (this.candidatesQuery.page === 1 && this.candidates.length) {
        this.fetchCandidate(this.candidates[0].id)
      }

      if (!this.candidates.length) {
        this.selectedCandidate = null
      }
    },

    removeCandidateFromMoveList(candidateId) {
      this.candidatesToMove = this.candidatesToMove.filter(candidate => candidate !== candidateId)
    },

    jobChanged(job) {
      this.$router.push({ name: 'client-assessments-show', params: { id: job.uuid } })
    },

    filterCandidatesByStage(stage) {
      this.chosenStage = stage
      this.candidatesQuery = {
        ...this.candidatesQuery,
        stage_slug: stage.slug,
        page: 1
      }
    },

    qualificationStatusUpdate(event) {
      const candidateIds = event.candidates.map(candidate => candidate.id)

      let path

      if (event.action === 'Requalify') {
        path = organisationsApi.requalifyCandidates
      } else {
        path = organisationsApi.disqualifyCandidates
      }

      return path(
        this.organisationId,
        this.job.uuid,
        { candidates: candidateIds }
      )
        .then(candidates => {
          this.fetchCandidatesAndJob()
        })
        .catch(error => {
          throw error
        })
    },

    deleteCandidates(candidates) {
      console.log('Delete these', candidates)
    },

    moveCandidatesToPipelineStage(data) {
      let candidateIds = []

      if (data.candidateId) {
        candidateIds.push(data.candidateId)
      } else {
        candidateIds = this.candidatesToMove.map(candidate => candidate.id)
      }

      return organisationsApi.moveCandidatesToPipelineStage(
        this.organisationId,
        this.job.uuid,
        {
          candidates: candidateIds,
          new_slug: data.stageSlug
        }
      )
        .then(() => {
          this.candidatesToMove = []
          this.fetchCandidatesAndJob()
        })
        .catch(error => {
          throw error
        })
    }
  }
}
</script>
