<template>
  <div v-if="hasAntiCheatData">
    <ul
      class="flex mt-3 space-x-5 text-xs text-gray-600"
    >
      <li class="flex item-center">
        <TooltipButton>
          <template v-slot:content>
            <span class="sr-only">Webcam enabled</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 10l4.553-2.276A1 1 0 0121 8.618v6.764a1 1 0 01-1.447.894L15 14M5 18h8a2 2 0 002-2V8a2 2 0 00-2-2H5a2 2 0 00-2 2v8a2 2 0 002 2z"
              />
            </svg>
            <span class="ml-1">
              <Check
                v-if="webcam.includes(true)"
                class="w-4 h-4 text-secondary"
              />
              <Cross
                v-else
                class="w-4 h-4 text-gray-600"
              />
            </span>
          </template>
          <template v-slot:tooltip>
            Webcam enabled
          </template>
        </TooltipButton>
      </li>
      <li class="flex item-center">
        <TooltipButton>
          <template v-slot:content>
            <span class="sr-only">Full screen always active</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
              />
            </svg>
            <span class="ml-1">
              <Check
                v-if="hasExitedFullScreen.includes(false)"
                class="w-4 h-4 text-secondary"
              />
              <Cross
                v-else
                class="w-4 h-4 text-gray-600"
              />
            </span>
          </template>
          <template v-slot:tooltip>
            Full screen always active
          </template>
        </TooltipButton>
      </li>
      <li class="flex item-center">
        <TooltipButton>
          <template v-slot:content>
            <span class="sr-only">Mouse always in test window</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
              />
            </svg>
            <span class="ml-1">
              <Check
                v-if="hasLeaveAssessmentWindowCount"
                class="w-4 h-4 text-secondary"
              />
              <Cross
                v-else
                class="w-4 h-4 text-gray-600"
              />
            </span>
          </template>
          <template v-slot:tooltip>
            Mouse always in test window
          </template>
        </TooltipButton>
      </li>
      <li class="flex item-center">
        <TooltipButton>
          <template v-slot:content>
            <span class="sr-only">Location remained the same</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-4 h-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              stroke-width="2"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <span class="ml-1">
              <Cross
                v-if="ipAddressChanged.includes(true)"
                class="w-4 h-4 text-gray-600"
              />
              <Check
                v-else
                class="w-4 h-4 text-secondary"
              />
            </span>
          </template>
          <template v-slot:tooltip>
            Location remained the same
          </template>
        </TooltipButton>
      </li>
    </ul>
  </div>
</template>

<script>
import Check from '@components/Icons/Isolated/Check'
import Cross from '@components/Icons/Isolated/Cross'
import TooltipButton from '@components/TooltipButton'

export default {
  components: {
    Check,
    Cross,
    TooltipButton
  },

  props: {
    candidate: {
      type: Object,
      default: null
    }
  },

  computed: {
    hasAntiCheatData() {
      if (!this.candidate) {
        return false
      }
      // @TODO - We dont display anti-cheat data for legacy
      // candidates. Be good to find a better way to deal with legacy
      // candidates who dont have any anti-cheat data.
      return Boolean(this.candidate.attempts.find(attempt => attempt.ipAddressChanged !== null))
    },

    hasExitedFullScreen() {
      return this.candidate.attempts.map(attempt => attempt.hasExitedFullScreen)
    },

    hasLeaveAssessmentWindowCount() {
      const mouseMoves = this.candidate.attempts.map(attempt => attempt.leaveAssessmentWindowCount)

      return mouseMoves.some(move => {
        return move > 0
      })
    },

    webcam() {
      return this.candidate.attempts.map(attempt => attempt.webcam)
    },

    ipAddressChanged() {
      return this.candidate.attempts.map(attempt => attempt.ipAddressChanged)
    }
  }

}
</script>
