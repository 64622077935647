<template>
  <div>
    <a
      href="javascript:;"
      class="hover:text-red-400 duration-150"
      @click="modalOpen = true"
    >
      <span class="hidden sm:inline-block sr-only">Filter</span>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        :class="['h-6 w-6 text-gray-500 hover:text-secondary', {'text-secondary' : appliedFilters.length}]"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
        />
      </svg>
    </a>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div class="border-b-2 bg-white">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="font-medium text-lg">
            Filter candidates
          </h3>
          <button
            v-if="!isProcessing"
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              width="16px"
              height="16px"
              view-box="0 0 14 14"
              class="transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>
      <div
        v-if="!isProcessing"
        class="m-6"
      >
        <ul>
          <li
            class="p-1 space-y-2"
          >
            <span
              v-for="(filter, filterIdx) in filters"
              :key="filterIdx"
              class="flex mr-4"
            >
              <label class="flex items-center">
                <input
                  v-model="appliedFilters"
                  type="checkbox"
                  :value="filter.param"
                  class="form-checkbox rounded"
                />

                <span class="ml-2">
                  {{ filter.name }}
                </span>
              </label>
            </span>
          </li>
        </ul>

        <div class="flex justify-between mt-10 mb-6">
          <BaseButton
            variant="gray"
            @click="close"
          >
            Cancel
          </BaseButton>
          <BaseButton
            variant="green"
            :loading="loading"
            @click="applyFilters"
          >
            Apply filters
          </BaseButton>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import Modal from '@components/Modal'
import Plus from '@components/Icons/Plus'

export default {
  components: {
    Icon,
    Modal,
    Plus
  },

  props: {
    value: {
      type: [Object, Array],
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isProcessing: false,
      modalOpen: false,
      errorMessage: null,
      filters: [
        {
          name: 'Tests completed',
          param: 'job_finished'
        },
        {
          name: 'Tests in progress',
          param: 'in_progress'
        }
      ],
      appliedFilters: []
    }
  },

  computed: {
    appliedFiltersObject() {
      if (this.appliedFilters.length === this.filters.length) {
        return {}
      } else {
        return Object.assign({}, ...this.appliedFilters.map(key => ({ [key]: true })))
      }
    }
  },

  watch: {
    loading(val) {
      this.$nextTick(() => {
        if (!val) {
          this.modalOpen = false
        }
      })
    }
  },

  methods: {
    close() {
      this.appliedFilters = []
      this.modalOpen = false
    },

    applyFilters(filters) {
      this.$emit('input', this.appliedFiltersObject)
    }

  }
}
</script>
