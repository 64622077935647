<template>
  <form
    class="px-6 pt-6 pb-8 bg-gray-100"
    novalidate
    @submit.prevent
  >
    <label class="block">
      <span class="text-gray-800 text-sm">Candidate’s name</span>
      <input
        v-model="$v.form.fullName.$model"
        autocomplete="given-name"
        class="form-input mt-1 block w-full"
      >
    </label>

    <label class="block mt-6">
      <span class="text-gray-800 text-sm">Candidate’s email address</span>
      <input
        v-model="$v.form.email.$model"
        type="email"
        class="form-input mt-1 block w-full"
        autocomplete="email"
      >
    </label>

    <section class="mx-auto text-right relative mt-8 h-12">
      <BaseButton
        :loading="isLoading"
        :disabled="!canSubmit"
        @click="submit"
      >
        Save Candidate
      </BaseButton>

      <Error
        v-for="(errorMessage, index) in errorMessages"
        :key="index"
        class="w-full text-center"
      >
        {{ errorMessage }}
      </Error>
    </section>
  </form>
</template>

<script>
import Error from '@components/FormElements/Error'

import organisationCandidatesApi from '@api/organisationCandidates'
import { mapGetters } from 'vuex'
import { required, email } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

export default {
  components: {
    Error
  },

  mixins: [validationMixin],

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      errorMessages: [],

      form: {
        fullName: null,
        email: null
      }
    }
  },

  computed: {
    ...mapGetters({
      organisationId: 'employers/organisationId'
    }),

    /**
     * @return {Boolean}
     */
    canSubmit() {
      return this.form.fullName && this.form.email && this.$v.form.email.email
    }
  },

  validations: {
    form: {
      fullName: {
        required
      },
      email: {
        required,
        email
      }
    }
  },

  created() {
    this.form.fullName = this.candidate.firstName + ' ' + this.candidate.surname
    this.form.email = this.candidate.email
  },

  methods: {
    /**
     * Submit the form
     */
    submit() {
      this.isLoading = true
      this.errorMessages = []

      if (!this.$v.form.email.email) {
        this.errorMessages = ['Please enter a valid email']
        this.isLoading = false
        return
      }

      organisationCandidatesApi
        .update(this.organisationId, this.candidate.id, this.form)
        .then((candidate) => {
          this.$emit('candidateUpdated')
        })
        .catch(error => {
          if (error.response.data.errors) {
            this.errorMessages = error.response.data.errors
          } else {
            console.error(error.message)
            this.errorMessages = ['Sorry, an error occured']
          }
          this.isLoading = false
        })
    }
  }
}
</script>
