<template>
  <div>
    {{ formattedTenure.start }} - {{ formattedTenure.end }}
  </div>
</template>

<script>
import { monthNumToName } from '@utils/yearsMonths'

export default {
  props: {
    tenure: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      monthNumToName
    }
  },

  computed: {
    formattedTenure() {
      if (!this.tenure) {
        return
      }
      return {
        start: `${this.monthNumToName(this.tenure.start.month)} ${this.tenure.start.year}`,
        end: this.endDate
      }
    },

    endDate() {
      if (this.tenure.current) {
        return 'present'
      } else {
        return `${this.monthNumToName(this.tenure.end.month)} ${this.tenure.end.year}`
      }
    }
  }
}
</script>
