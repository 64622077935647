<template>
  <AccessibleDropdown
    :options="pipeline"
    :loading="loading"
    :disabled="filter === 'disqualified_only'"
    align="right"
    @input="handleChoice($event)"
  >
    <template v-slot:button="{ isOpen }">
      <span
        class="block h-9 px-2 inline-flex items-center border-l-2 border-secondary-400 rounded-tr rounded-br"
        :class="[isOpen ? 'bg-secondary-400' : 'bg-secondary']"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4 flex-shrink-0 text-white"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </span>
    </template>

    <template v-slot:buttonContent="{ option }">
      <span class="flex justify-between items-center w-full">
        <span>{{ option.name }}</span>

        <span>
          <svg
            v-if="option.slug === stage.slug"
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 text-secondary"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M5 13l4 4L19 7"
            />
          </svg>
        </span>
      </span>
    </template>
  </AccessibleDropdown>
</template>

<script>
import AccessibleDropdown from '@components/AccessibleDropdown'

export default {
  components: {
    AccessibleDropdown
  },

  props: {
    candidate: {
      type: Object,
      default: null
    },

    pipeline: {
      type: Array,
      default: null
    },

    stage: {
      type: Object,
      default: null
    },

    filter: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      open: true,
      loading: false
    }
  },

  methods: {
    handleChoice(stage) {
      this.$emit('move-to-stage', { stageSlug: stage.slug, candidateId: this.candidate.id })
      this.open = false
    }
  }
}
</script>
