<template>
  <div>
    <span
      v-if="!photo || maskedAttributes.includes('photo')"
      :class="['inline-block rounded-full overflow-hidden bg-gray-100', size]"
    >
      <svg
        fill="currentColor"
        viewBox="0 0 24 24"
        class="h-full w-full text-gray-300"
      >
        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z"></path></svg>
    </span>

    <div
      v-else
      class="flex-shrink-0"
    >
      <img
        :class="['mx-auto rounded-full', size]"
        :src="photoUrl"
        alt="Candidate Profile Photo"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    photo: {
      type: [Object, String],
      default: null
    },

    size: {
      type: String,
      default: 'h-16 w-16'
    }
  },

  computed: {
    /**
     * @return {Object}
     */
    ...mapGetters({
      maskedAttributes: 'organisations/maskedAttributes'
    }),

    /**
     * @return {string}
     */
    photoUrl() {
      if (!this.photo) {
        return
      }

      if (typeof this.photo === 'string') {
        return this.photo
      } else {
        return `https://res.cloudinary.com/${this.photo.cloudName}/image/upload/w_100,h_100,c_fill,q_70/v${this.photo.version}/${this.photo.publicId}.jpg`
      }
    }
  }

}
</script>
