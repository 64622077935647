export const examIcons = {
  'accountability-situational-judgement-test-6e383': 'Map',
  'drives': 'Map',
  'foundation-logical-reasoning': 'Solar',
  'foundation-mechanical-reasoning': 'Cog',
  'foundation-numerical-reasoning': 'Geometry',
  'foundation-situational-judgement': 'Map',
  'foundation-verbal-reasoning': 'Notepad',
  'interpersonal-skills-situational-judgement-test-6e37d': 'Map',
  'leadership-situational-judgement-test-6e376': 'Map',
  'logical': 'Solar',
  'logical-reasoning': 'Solar',
  'logical-reasoning-7114a': 'Solar',
  'mechanical': 'Cog',
  'numerical-reasoning': 'Geometry',
  'numerical-reasoning-71195': 'Geometry',
  'mechanical-reasoning-63aa4': 'Cog',
  'personality': 'Avatar',
  'personality-2021-11': 'Avatar',
  'situational': 'Map',
  'situational-judgement': 'Map',
  'skill': 'Bulb',
  'skills': 'Bulb',
  'standard-mechanical-reasoning': 'Cog',
  'teamwork-situational-judgement-test-6e37b': 'Map',
  'time-management-situational-judgement-test-6e386': 'Map',
  'verbal-reasoning': 'Notepad',
  'verbal-reasoning-70c69': 'Notepad'
}
