<template>
  <div>
    <div class="items-center justify-between p-6 sm:flex">
      <div class="flex items-center">
        <Icon
          view-box="0 0 304 304"
          fill="none"
          class="w-12 h-12"
        >
          <component
            :is="icon"
          />
        </Icon>
        <div class="ml-4">
          <h2 class="text-xl sm:text-2xl">
            {{ exam.name }}
          </h2>
          <p class="text-sm text-gray-600 capitalize">
            {{ exam.level }}
          </p>
        </div>
      </div>

      <a
        v-if="$usesFeature('classic-jobs')"
        href="javascript:;"
        class="flex items-end mt-8 text-sm sm:mt-0"
        @click="toggleAccordion"
      >
        <span>{{ accordionButtonText }} results</span>
        <Icon
          view-box="0 0 24 24"
          fill="none"
          :class="['w-6 h-6 transform duration-300 ease-in-out', accordionOpen ? 'rotate-0' : 'rotate-180']"
        >
          <path d="M8.7 13.7a1 1 0 1 1-1.4-1.4l4-4a1 1 0 0 1 1.4 0l4 4a1 1 0 0 1-1.4 1.4L12 10.42l-3.3 3.3z"></path>
        </Icon>
      </a>
    </div>

    <transition
      enter-active-class="transition-all duration-150 ease-out"
      leave-active-class="transition-all duration-150 ease-in"
      enter-class="opacity-0"
      leave-class="opacity-100"
      enter-to-class="opacity-100"
      leave-to-class="opacity-0"
    >
      <div v-show="accordionOpen">
        <div class="p-6 text-gray-600 border-t border-gray-300">
          <p class="text-sm sm:w-2/3 sm:mb-0 sm:mr-4">
            Candidates are given 20 points to distribute across the Drives that motivate them most at this point in their career.
          </p>
        </div>
        <ul class="w-full p-6 space-y-8">
          <li
            v-for="(drive, index) in sortedScores(exam.attempt.scores)"
            :key="index"
          >
            <div class="flex items-center justify-between leading-none">
              <div>
                <p class="w-32 mr-2 text-lg font-medium capitalize">
                  {{ drive.name }}
                </p>
              </div>
              <div
                class="w-full h-2 mx-4 bg-gray-300"
              >
                <div
                  :class="['h-full bg-gray-800']"
                  :style="`width: ${drivesScore(drive.score)}%`"
                >
                </div>
              </div>
              <div class="w-48 text-center">
                <span class="text-3xl text-gray-800">{{ drive.score }}</span> / <span class="text-sm text-sp">{{ maxScore }}</span>
              </div>
            </div>
            <p class="text-sm text-gray-600">
              {{ driveDescription(drive.name) }}
            </p>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import Icon from '@components/Icons/Icon'
import { drives } from '@data/drivesTest.js'

export default {
  components: {
    Icon
  },

  props: {
    icon: {
      type: Function,
      default: null
    },
    exam: {
      type: Object,
      default: null
    }
  },

  data() {
    return {
      drives,
      accordionOpen: true,
      maxScore: 5
    }
  },

  computed: {
    /**
     * @return {Boolean}
     */
    accordionButtonText() {
      return this.accordionOpen ? 'Hide' : 'View'
    }
  },

  methods: {
    /**
     * @return {Array}
     */
    sortedScores(scores) {
      const sortableScores = Object.entries(scores).map(
        ([key, value]) => ({
          name: key,
          score: value
        })
      )
      return sortableScores.sort((a, b) => b.score - a.score)
    },

    /**
     * @return {String}
     */
    drivesScore(score) {
      return score * 100 / this.maxScore
    },

    /**
     * @return {Boolean}
     */
    toggleAccordion() {
      this.accordionOpen = !this.accordionOpen
    },

    /**
     * @return {String}
     */
    driveDescription(driveName) {
      const drive = this.drives.find(drive => drive.name === driveName)
      return drive.description
    }
  }
}
</script>
