<template>
  <div class="relative m-auto">
    <canvas
      :id="chartId"
      :width="width"
      :height="height"
    > </canvas>
  </div>
</template>

<script>
import Chart from 'chart.js'
import { themeColors } from '@utils/themeColors'

export default {
  props: {
    exam: {
      type: Object,
      default: null
    },
    index: {
      type: Number
    }
  },

  data() {
    return {
      themeColors: themeColors(),
      chart: {},
      width: '100%',
      height: '100%',
      chartId: `curve-${this.index}`
    }
  },

  computed: {
    /**
     *
     * @return {Number}
     */
    candidateScoreBracket() {
      return this.calculateScoreBracket(this.exam.attempt.scorePercentage)
    },

    /**
     *
     * @return {Number}
     */
    averageScoreBracket() {
      return this.calculateScoreBracket(this.exam.averageScorePercentage)
    }
  },

  watch: {
    id: {
      async handler(newOption, oldOption) {
        await this.chart.destroy()
        await this.drawChart()
      }
    }
  },

  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },

  methods: {
    handleResize() {
      // Calculate new canvas size based on window

      if (window.innerWidth > 1000) {
        this.width = null
        this.height = null
      } else if (window.innerWidth < 1000) {
        this.height = '50vh'
        this.width = '50vw'
      }

      this.$nextTick(() => {
        this.drawChart()
      })
    },

    findCandidateScoreIndex(labels) {
      return labels.findIndex((label) => label > this.exam.attempt.scorePercentage)
    },

    findAverageScoreIndex(labels) {
      return labels.findIndex((label) => label > this.exam.averageScorePercentage)
    },

    drawChart() {
      const ctx = document.getElementById(this.chartId)
      // eslint-disable-next-line

      Chart.defaults.global.defaultFontColor = '#2d3748'
      Chart.defaults.global.defaultFontFamily = "'Circular', Helvetica, sans-serif"
      Chart.defaults.global.defaultFontSize = 12
      Chart.defaults.global.legend.display = false

      const backgroundColors = []

      this.exam.distributionGraph.datasets[0].data.forEach((value, index) => {
        if (index === this.findCandidateScoreIndex(this.exam.distributionGraph.labels)) {
          backgroundColors[index] = '#2fc487'
        } else if (index === this.findAverageScoreIndex(this.exam.distributionGraph.labels)) {
          backgroundColors[index] = '#bbbbc6'
        } else {
          backgroundColors[index] = '#1b1c40'
        }
      })

      this.chart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: this.exam.distributionGraph.labels,
          datasets: [
            {
              data: this.exam.distributionGraph.datasets[0].data,
              backgroundColor: backgroundColors
            }
          ]
        },
        options: {
          tooltips: {
            callbacks: {
              title: function (tooltipItems, data) {
                return ''
              },
              label: function (tooltipItem, data) {
                return tooltipItem.xLabel + '%'
              }
            }
          },
          scales: {
            yAxis: {
              display: false
            }
          }
        }
      })
    }
  }
}
</script>
