<template>
  <div>
    <TooltipButton>
      <template v-slot:content>
        <BaseButton
          variant="salmon"
          size="standard"
          @click="modalOpen = true"
        >
          <template slot="iconMiddle">
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5"
              stroke="currentColor"
              stroke-width="2"
            >
              <Edit />
            </Icon>
          </template>
        </BaseButton>
      </template>
      <template v-slot:tooltip>
        Edit Candidate
      </template>
    </TooltipButton>

    <Modal
      :open="modalOpen"
      @close="modalOpen = false"
    >
      <div class="bg-white border-b-2">
        <div class="flex justify-between px-6 pb-6">
          <h3 class="text-lg font-medium">
            Edit candidate
          </h3>
          <button
            class="text-gray-500 focus:outline-none"
            @click="modalOpen = false"
          >
            <Icon
              view-box="0 0 24 24"
              class="w-5 h-5 transform rotate-45"
            >
              <Plus />
            </Icon>
          </button>
        </div>
      </div>

      <EditCandidateForm
        v-if="modalOpen"
        :candidate="candidate"
        @candidateUpdated="candidateUpdated()"
      />
    </Modal>
  </div>
</template>

<script>
import Edit from '@components/Icons/Edit'
import EditCandidateForm from '@components/Candidates/EditCandidateForm'
import Icon from '@components/Icons/Icon'
import Modal from '@components/Modal'
import Plus from '@components/Icons/Plus'
import TooltipButton from '@components/TooltipButton'

export default {
  components: {
    Edit,
    EditCandidateForm,
    Icon,
    Modal,
    Plus,
    TooltipButton
  },

  props: {
    candidate: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      modalOpen: false
    }
  },

  watch: {
    /**
     * Watch for the modal closing and reset the sitting
     *
     * @param {Boolean}
     */
    modalOpen(value) {
      if (value === false) {
        this.sitting = null
      }
    }
  },

  methods: {
    /**
     * Trigger candidateUpdated event and close the modal
     */
    candidateUpdated() {
      this.$emit('candidateUpdated')
      this.modalOpen = false
    }
  }
}
</script>
