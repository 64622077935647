<template>
  <div class="bg-white w-full">
    <div class="p-10 sm:flex-shrink-0 text-center mx-auto">
      <Percentages class="max-w-sm mx-auto" />
      <div class="max-w-md mx-auto">
        <h2 class="text-3xl my-6">
          {{ title }}
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import Percentages from '@components/Vectors/Percentages'

export default {
  components: {
    Percentages
  },

  props: {
    job: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'The stage has no candidates'
    }
  }
}
</script>
