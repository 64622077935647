<template>
  <AccessibleDropdown
    :options="options"
    :loading="loading"
    @input="handleClick($event)"
  >
    <template v-slot:button="{ isOpen }">
      <span
        class="text-sm rounded-lg px-3 py-1"
        :class="[isOpen ? 'bg-gray-300' : 'bg-gray-100']"
      >
        <span>Bulk actions</span>
      </span>
    </template>

    <template v-slot:buttonContent="{ option }">
      <component
        :is="option.icon"
        v-if="option.icon"
        class="h-6 w-6 mr-2 text-gray-600"
      />
      <span>{{ option.name }}</span>
    </template>
  </AccessibleDropdown>
</template>

<script>
import AccessibleDropdown from '@components/AccessibleDropdown'

export default {
  components: {
    AccessibleDropdown
  },

  props: {
    loading: {
      type: Boolean,
      default: false
    },

    filter: {
      type: String,
      default: 'Qualified'
    }
  },

  data() {
    return {
      chosenItem: null,
      options: [
        {
          name: 'Move To Stage',
          icon: () => import('@components/Icons/Isolated/Arrow')
        }
        // NOTE: I need to work out a way of loading the DeleteCandidateButton in here.
        // I can add the component, but the click event from the DropdownButton component (in AccessibleDropdown) masks
        // the DeleteCandidateButton click event. Will investigate.
        // {
        //   name: 'Delete',
        //   icon: () => import('@components/Icons/Isolated/Bin')
        // }
      ]
    }
  },

  created() {
    const filters = {
      'Disqualified': {
        name: 'Requalify',
        icon: () => import('@components/Icons/Isolated/Requalify')
      },
      'Qualified': {
        name: 'Disqualify',
        icon: () => import('@components/Icons/Isolated/Disqualify')
      }
    }
    this.options.push(filters[this.filter])
  },

  methods: {
    handleClick(event) {
      this.chosenItem = event

      const events = {
        'Move To Stage': 'move-to-stage',
        'Disqualify': 'qualification-status-update',
        'Requalify': 'qualification-status-update',
        'Delete': 'delete-candidates'
      }
      this.$emit(events[event.name], event.name)
    }
  }
}
</script>
