<template>
  <div class="rounded-lg bg-white shadow p-6">
    <h2 class="text-lg font-medium mb-8">
      Education History
    </h2>
    <div
      v-if="educationHistory.length"
      class="space-y-8 divide-y divide-gray-300 divide-dashed -mt-8"
    >
      <dl
        v-for="(entry, index) in educationHistory"
        :key="index"
        class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2 md:grid-cols-4 pt-8"
      >
        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Institution
          </dt>
          <dd class="mt-1 text-sm text-gray-800">
            {{ entry.institute }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Course
          </dt>
          <dd class="mt-1 text-sm text-gray-800">
            {{ entry.course }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Classification
          </dt>
          <dd class="mt-1 text-sm text-gray-800">
            {{ entry.type }} {{ entry.grade }}
          </dd>
        </div>

        <div class="sm:col-span-1">
          <dt class="text-sm font-medium text-gray-500">
            Tenure
          </dt>
          <dd class="mt-1 text-sm text-gray-800">
            {{ entry.startYear }} - {{ entry.endYear }}
          </dd>
        </div>
      </dl>
    </div>
    <div
      v-else
      class="border-2 border-dashed border-gray-200 rounded-lg h-32 text-gray-400 text-sm flex flex-col justify-center items-center"
    >
      <p>Incomplete</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    educationHistory: {
      type: Array,
      default: null
    }
  }
}
</script>
